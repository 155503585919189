import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Alert } from '@components/alert';
import LoadingAnimation from '@components/LoadingAnimation';
import ReservationsForm from '@components/modules/aereo/ReservationForm';
import SearchReservations from '@components/modules/aereo/SearchReservations';
import { ButtonV2 } from '@components/new-components/button-v2';
import { SelectV2 } from '@components/new-components/select-v2';
import PageHeader from '@components/page-header';
import BookingRouteCard from '@components/route-card/BookingRouteCard';

import { useAereoReservation } from '@hooks/useAereo';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { ORDER_BY_FLIGHTS } from '@system/enums';
import { getStateNameByIata } from '@system/utils';
import { PagePath } from '../pages-config';

import './AereoReservasResultados.scss';

const calculateTotalPrice = (flights, categories) => {
  return flights.map((flight) => ({
    ...flight,
    totalPrice:
      Number(flight.minPriceAdt) * categories.adt +
      Number(flight.minPriceChd) * categories.chd +
      Number(flight.minPriceInf) * categories.inf +
      Number(flight.departureTax),
  }));
};

const FILTER_FLIGHTS = {
  [ORDER_BY_FLIGHTS.RECOMMEND]: (flights, totalPassengers) => {
    const flightsWithPrice = calculateTotalPrice(flights, totalPassengers);

    const vPairFlights = flightsWithPrice
      .filter((flight) => flight.departureAirline === 'VPair')
      .sort((a, b) => a.totalPrice - b.totalPrice);

    const otherFlights = flightsWithPrice.filter(
      (flight) => flight.departureAirline !== 'VPair',
    );

    return [...vPairFlights, ...otherFlights];
  },
  [ORDER_BY_FLIGHTS.AVAILABLE]: (flights) => {
    return flights.sort((a, b) => {
      return (
        new Date(a.departureDate).getTime() -
        new Date(b.departureDate).getTime()
      );
    });
  },
  [ORDER_BY_FLIGHTS.MIN_PRICE]: (flights, totalPassengers) => {
    return calculateTotalPrice(flights, totalPassengers).sort((a, b) => {
      return a.totalPrice - b.totalPrice;
    });
  },
  [ORDER_BY_FLIGHTS.MAX_PRICE]: (flights, totalPassengers) => {
    return calculateTotalPrice(flights, totalPassengers).sort((a, b) => {
      return b.totalPrice - a.totalPrice;
    });
  },
};

const getSummaries = async (params) => {
  const { data } = await api.get('charters/summaries', { params });
  return data;
};

export default function AereoReservasResultados() {
  const { session } = useAuthentication();
  const navigate = useNavigate();

  const { isFormOpen, queryType, handleUpdateQueryType, params, searchParams } =
    useAereoReservation();

  const {
    originIata,
    destinationIata,
    departureDate,
    returnDate,
    months,
    adultPassengers,
    childrenPassengers,
    babyPassengers,
    tripType,
    orderBy,
  } = params;

  const passengers =
    Number(adultPassengers) +
    Number(childrenPassengers) +
    Number(babyPassengers);

  const passengerQuantityByCategories = {
    adt: Number(adultPassengers),
    chd: Number(childrenPassengers),
    inf: Number(babyPassengers),
  };

  const [flights, setFlights] = useState({
    result: [],
    count: 0,
  });
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [relatedFlights, setRelatedFlights] = useState([]);
  const [pageRelatedFlights, setPageRelatedFlights] = useState(1);
  const [totalPagesRelatedFlights, setTotalPagesRelatedFlights] = useState(1);

  const [databaseAeroportos, setDatabaseAeroportos] = useState([]);
  const [sortFilter, setSortFilter] = useState(
    orderBy ?? ORDER_BY_FLIGHTS.RECOMMEND,
  );

  function setSearch(key, value) {
    const params = new URLSearchParams(searchParams);
    params.set(key, value);
    navigate({
      pathname: PagePath.AereoReservasResultados,
      search: `?${params.toString()}`,
    });
  }

  function validateSeat(id) {
    const params = new URLSearchParams(searchParams);
    navigate(PagePath.AereoReservasCheckout, {
      state: {
        charterId: id,
        step: 'checkout',
        reservationsPageQueryParams: params.toString(),
      },
    });
  }

  function checkToday(x) {
    let today = new Date().toISOString().slice(0, 10);

    if (x < today) {
      return false;
    }

    return true;
  }

  function handleSortingChange(e) {
    const value = e.target.value;
    setSearch('orderBy', value);
    setSortFilter(value);
  }

  function getSurveyMessage(props) {
    const result = props.result[0];
    const dest_city =
      result?.destinationCity?.split(`/`)[0] ||
      result?.charter?.destinationAirport?.city ||
      '';

    let message = '';
    if (props.count === 1) {
      message = `Encontrado 1 resultado para ${dest_city}`;
    } else {
      message = `Encontrados ${props.count} resultados para ${dest_city}`;
    }

    return message;
  }

  async function fetchFlights(pageNum) {
    setLoading(true);

    try {
      const data = await getSummaries({
        active: 1,
        limit: 10,
        page: pageNum,
        originAirport: originIata,
        destinationAirport: destinationIata,
        departureDate,
        returnDate,
        months,
        passengers,
        tripType,
      });

      if (!data.result.length) {
        setFlights({ result: [], count: 0 });
        loadRelatedFlights();
        return;
      }

      setFlights((prevData) => {
        // const results = [...prevData.result, ...data.result];
        const results = [...data.result];
        const uniques = results.filter((flight, index, arr) => {
          return index === arr.findIndex((el) => el.id === flight.id);
        });

        return {
          ...prevData,
          result: uniques,
          count: data.total,
        };
      });

      setTotalPages(data.totalPages);
      setPage(pageNum);
    } catch (error) {
      console.error('Erro ao buscar voos:', error);
    } finally {
      setLoading(false);
    }
  }

  function loadMoreFlights() {
    if (page < totalPages) {
      fetchFlights(page + 1);
    }
  }

  async function fetchRelatedFlights(pageNum) {
    setLoading(true);

    const queryParamsMap = [
      {
        type: 'initial',
        params: (props) => ({
          ...props,
          departureDate,
          returnDate,
          months,
          originAirport: originIata,
          destinationAirport: destinationIata,
        }),
      },
      {
        type: 'sameOriginDestinationDifferentDates',
        params: (props) => ({
          ...props,
          originAirport: originIata,
          destinationAirport: destinationIata,
        }),
      },
      {
        type: 'sameStateOriginDifferentDates',
        params: (props) => ({
          ...props,
          originState: getStateNameByIata(originIata, databaseAeroportos)
            .split('/')
            .at(-1), // estado da origem
          destinationAirport: destinationIata,
          excludeDate: departureDate,
        }),
      },
      {
        type: 'sameOriginDifferentDestinationDates',
        params: (props) => ({
          ...props,
          originAirport: originIata,
          excludeDestinationAirport: destinationIata,
          excludeDate: departureDate,
        }),
      },
      {
        type: 'allFlights',
        params: (props) => ({
          ...props,
        }),
      },
    ];

    try {
      const query = queryParamsMap.find((q) => q.type === queryType);
      const params = query.params({
        active: 1,
        limit: 10,
        page: pageNum,
        passengers,
      });

      const data = await getSummaries(params);

      if (data.result.length === 0 && queryType !== 'allFlights') {
        loadRelatedFlights();
        return;
      }

      setRelatedFlights((prevData) => {
        const results = [...prevData, ...data.result];
        const uniques = results.filter((flight, index, arr) => {
          return index === arr.findIndex((el) => el.id === flight.id);
        });

        return uniques;
      });

      setTotalPagesRelatedFlights(data.totalPages);
      setPageRelatedFlights(pageNum);
    } catch (error) {
      console.error('Erro ao buscar voos relacionados: ', error);
    }

    setLoading(false);
  }

  function loadMoreExtraFlights() {
    if (pageRelatedFlights < totalPagesRelatedFlights) {
      fetchRelatedFlights(pageRelatedFlights + 1);
    }
  }

  function loadRelatedFlights() {
    switch (queryType) {
      case 'initial':
        handleUpdateQueryType('sameOriginDestinationDifferentDates');
        break;
      case 'sameOriginDestinationDifferentDates':
        handleUpdateQueryType('sameStateOriginDifferentDates');
        break;
      case 'sameStateOriginDifferentDates':
        handleUpdateQueryType('sameOriginDifferentDestinationDates');
        break;
      case 'sameOriginDifferentDestinationDates':
        handleUpdateQueryType('allFlights');
        break;
      default:
        break;
    }
  }

  async function getAllAirports() {
    const response = await api.get('/airports');
    const translatedAirports = response?.data?.map((airport) => ({
      iata: airport.iata,
      icao: airport.icao,
      nome: airport.name,
      cidade: airport.city,
      uf: airport.state,
    }));
    setDatabaseAeroportos(translatedAirports ?? []);
  }

  useEffect(() => {
    getAllAirports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (queryType === 'initial') {
      fetchFlights(1);
    } else {
      fetchRelatedFlights(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryType, searchParams]);

  const pageTitle = session?.agency?.nomeFantasia ?? 'Buscar reserva';

  const filteredFlights = FILTER_FLIGHTS[orderBy](
    flights.result,
    passengerQuantityByCategories,
  );

  return (
    <section>
      {loading && <LoadingAnimation />}

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Aéreo', link: PagePath.Aereo },
          { label: 'Reservas', link: PagePath.AereoReservas },
          {
            label: 'Listagem de reservas',
            link: PagePath.AereoReservasResultados,
          },
        ]}
        title={pageTitle}
      />
      <div className="flex flex-col gap-4">
        {isFormOpen ? (
          <ReservationsForm />
        ) : (
          <SearchReservations
            origin={originIata}
            destination={destinationIata}
            departureDate={departureDate}
            months={months}
            returnDate={returnDate}
            passengers={passengers}
            airports={databaseAeroportos}
            nameOrigin={
              databaseAeroportos.find((airport) => {
                return airport.iata === originIata;
              })?.nome
            }
            nameDestination={
              databaseAeroportos.find((airport) => {
                return airport.iata === destinationIata;
              })?.nome
            }
          />
        )}
        {!loading && flights.result.length === 0 && (
          <div className="flex w-full items-end justify-between">
            <Alert type="warning">
              <div className="flex flex-col">
                <span className="text-small font-semibold text-neutral-800">
                  Não encontramos resultados para a busca realizada
                </span>
              </div>
            </Alert>
          </div>
        )}
      </div>
      {flights.result.length > 0 && (
        <>
          <div className="mb-5 mt-4 flex items-end justify-between">
            <h3 className="mb-0 text-heading-3 text-neutral-800">
              {getSurveyMessage(flights)}
            </h3>
            <div className="w-[300px] pl-2">
              <span className="mb-2 block font-medium">Ordenação</span>
              <SelectV2
                label="Ordenação"
                className="flex h-12 w-full items-center justify-between rounded-md border border-neutral-600 bg-transparent px-2 py-0 text-sm font-normal placeholder:italic"
                value={sortFilter}
                onChange={handleSortingChange}
                options={[
                  { label: 'Recomendado', value: ORDER_BY_FLIGHTS.RECOMMEND },
                  {
                    label: 'Disponibilidades',
                    value: ORDER_BY_FLIGHTS.AVAILABLE,
                  },
                  { label: 'Menor preço', value: ORDER_BY_FLIGHTS.MIN_PRICE },
                  { label: 'Maior preço', value: ORDER_BY_FLIGHTS.MAX_PRICE },
                ]}
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            {filteredFlights.map((data, index) => (
              <BookingRouteCard
                key={index}
                baggage={data.departureBaggage}
                index={index}
                airports={databaseAeroportos}
                departure={{
                  company: data.departureAirline,
                  operator: data.departureOperator,
                  flight: data.departureFlight,
                  companyLogo: data.departureAirlineLogo,
                  origin: data.originAirport,
                  originTime: data.departureStartAt,
                  originDate: data.departureDate,
                  destination:
                    data.destinationAirport ??
                    data?.charter?.destinationAirport?.iata,
                  destinationTime: data.departureEndAt,
                  destinationDate: data.departureDate,
                  nextDay: data.departureNextDay,
                  totalScale: data.departureTotalScales,
                  totalConnection: data.departureTotalConnections,
                  destionationScale: '',
                  originScale: '',
                  connectionAirpoirt: '',
                }}
                return={{
                  company: data.returnAirline,
                  operator: data.returnOperator,
                  flight: data.returnFlight,
                  companyLogo: data.returnAirlineLogo,
                  origin: data.destinationAirport,
                  originTime: data.returnStartAt,
                  originDate: data.returnDate,
                  destination: data.originAirport,
                  destinationTime: data.returnEndAt,
                  destinationDate: data.returnDate,
                  nextDay: data.returnNextDay,
                  totalScale: data.returnTotalScales,
                  totalConnection: data.returnTotalConnections,
                  destionationScale: '',
                  originScale: '',
                  connectionAirpoirt: '',
                }}
                prices={{
                  adt: Number(data.minPriceAdt),
                  chd: Number(data.minPriceChd),
                  inf: Number(data.minPriceInf),
                  taxes: Number(data.departureTax),
                }}
                quantities={passengerQuantityByCategories}
                onClick={() =>
                  checkToday(data.departureDate) && validateSeat(data.charterId)
                }
                {...data}
              />
            ))}
            {page < totalPages && !(flights.result.length === 0) && (
              <div className="flex w-full justify-center">
                <ButtonV2
                  link
                  onClick={() => loadMoreFlights()}
                  loading={loading}
                >
                  <ButtonV2.Text>Ver mais resultados</ButtonV2.Text>
                </ButtonV2>
              </div>
            )}
          </div>
        </>
      )}
      {flights.result.length === 0 && relatedFlights.length > 0 && (
        <>
          <h3 className="mb-5 mt-8 text-heading-3 text-neutral-800">
            Confira os fretamentos que encontramos para você
          </h3>
          <div className="flex flex-col gap-4">
            {relatedFlights?.map((data, index) => (
              <BookingRouteCard
                key={index}
                baggage={data.departureBaggage}
                index={index}
                airports={databaseAeroportos}
                departure={{
                  company: data.departureAirline,
                  operator: data.departureOperator,
                  flight: data.departureFlight,
                  companyLogo: data.departureAirlineLogo,
                  origin: data.originAirport,
                  originTime: data.departureStartAt,
                  originDate: data.departureDate,
                  destination:
                    data.destinationAirport ??
                    data?.charter?.destinationAirport?.iata,
                  destinationTime: data.departureEndAt,
                  destinationDate: data.departureDate,
                  nextDay: data.departureNextDay,
                  totalScale: data.departureTotalScales,
                  totalConnection: data.departureTotalConnections,
                  destionationScale: '',
                  originScale: '',
                  connectionAirpoirt: '',
                }}
                return={{
                  company: data.returnAirline,
                  operator: data.returnOperator,
                  flight: data.returnFlight,
                  companyLogo: data.returnAirlineLogo,
                  origin: data.destinationAirport,
                  originTime: data.returnStartAt,
                  originDate: data.returnDate,
                  destination: data.originAirport,
                  destinationTime: data.returnEndAt,
                  destinationDate: data.returnDate,
                  nextDay: data.returnNextDay,
                  totalScale: data.returnTotalScales,
                  totalConnection: data.returnTotalConnections,
                  destionationScale: '',
                  originScale: '',
                  connectionAirpoirt: '',
                }}
                prices={{
                  adt: Number(data.minPriceAdt),
                  chd: Number(data.minPriceChd),
                  inf: Number(data.minPriceInf),
                  taxes: Number(data.departureTax),
                }}
                quantities={passengerQuantityByCategories}
                onClick={() =>
                  checkToday(data.departureDate) && validateSeat(data.charterId)
                }
                {...data}
              />
            ))}
          </div>
          {pageRelatedFlights < totalPagesRelatedFlights &&
            !(relatedFlights.length === 0) && (
              <div className="flex w-full justify-center">
                <ButtonV2 link onClick={loadMoreExtraFlights} loading={loading}>
                  <ButtonV2.Text>Ver mais resultados</ButtonV2.Text>
                </ButtonV2>
              </div>
            )}
        </>
      )}
    </section>
  );
}
