import { useEffect, useRef, useState } from 'react';
import { BiEditAlt } from 'react-icons/bi';
import { useNavigate, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import dayjs from 'dayjs';
import { ModalPassengerInfo } from '@components/modal/modal-reservation-management/passenger-info';
import { ModalCancelReservation } from '@components/modal/ModalCancelReservation';
import { ModalConfirmCancelReservation } from '@components/modal/ModalConfirmCancelReservation';
import { ButtonV2 } from '@components/new-components/button-v2';
import PageHeader from '@components/page-header';
import RouteCardDetailed from '@components/route-card/RouteCardDetailed';
import Tag from '@components/Tag';
import { Container } from '@layout';
import api from '@services/api';
import { formatCurrency, formatDocumentCPF } from '@system/utils';
import { PagePath } from '../pages-config';

export default function DetalhesReserva() {
  const navigate = useNavigate();
  const [openModalPassenger, setOpenModalPassenger] = useState(false);
  const [modalPassenger, setModalPassenger] = useState({});
  const [tableBody, setTableBody] = useState([]);
  const [booking, setBooking] = useState();
  const [paymentInfo, setPaymentInfo] = useState();
  const [flightDetail, setFlightDetail] = useState();
  const [airports, setAirports] = useState();
  const [cancelRule, setCancelRule] = useState();
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [reservationStatus, setReservationStatus] = useState('');
  const [reservationValueFee, setReservationValueFee] = useState('');
  const [reservationValueRefund, setReservationValueRefund] = useState('');
  const tableHeadings = [
    'Nome',
    'Data de nascimento',
    'CPF',
    'Localizador',
    'Valor',
    '',
  ];
  const { id } = useParams();
  const cancelRuleLoaded = useRef(false);

  useEffect(() => {
    loadAirports();
    loadTravelers();
    loadBooking();
    loadPayemntInfo();
  }, []);

  useEffect(() => {
    if (booking && booking.id && !cancelRuleLoaded.current) {
      loadBookingCancelRules();
      cancelRuleLoaded.current = true;
    }
  }, [booking]);

  useEffect(() => {
    loadCharter();
  }, [booking, airports]);

  const navigateGestaoReserva = () => {
    navigate(PagePath.ReservasGerenciar);
  };

  async function loadAirports() {
    api.get(`airports`).then((response) => {
      if (response.data) {
        setAirports(response.data);
      }
    });
  }

  async function loadCharter() {
    if (booking && booking.charterId && airports)
      api.get(`/charters/${booking.charterId}`).then((response) => {
        if (response.data) {
          setFlightDetail(response.data);
        }
      });
  }

  async function loadBookingCancelRules() {
    const token = localStorage.getItem('access_token');
    if (booking && booking.id) {
      const response = await api.get(`/bookings/${booking.id}/cancel-rule`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data) {
        setCancelRule(response.data);
      }
    }
  }

  async function postBookingCancellationRequest(valueFee, valueRefund) {
    const token = localStorage.getItem('access_token');

    if (booking && booking.id) {
      try {
        const payload = {
          bookingId: booking.id,
          ...(valueFee && { amount: valueFee, type: 'fines' }),
          ...(valueRefund && { amount: valueRefund, type: 'refund' }),
        };

        const response = await api.post(
          `/bookings/${booking.id}/cancel-rule`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response.data) {
          await loadBooking();
          setCancelRule(false);
          setIsCancelModalOpen(false);
          setIsConfirmModalOpen(true);
          setReservationStatus(reservationStatus);
        }
      } catch (error) {
        console.error('Erro ao cancelar a reserva:', error);
      }
    }
  }

  async function loadPayemntInfo() {
    api
      .get(`/bookings/${id}/paymentInfo`)
      .then((response) => {
        const { data } = response;
        if (!data) return;

        const updatedData = {
          ...data,
          status:
            data.paymentMethod === 'billing' && !data.status
              ? 'confirmed'
              : data.status,
        };

        setPaymentInfo(updatedData);
      })
      .catch((error) => alert(error));
  }

  async function loadBooking() {
    const response = await api.get(`/bookings/${id}`);
    if (response.data) {
      setBooking(response.data);
    }
  }

  const getStatusType = (value) => {
    switch (value) {
      case 'confirmed':
        return 'success';
      case 'created':
        return 'info';
      case 'paid':
        return 'success';
      case 'expired':
        return 'error';
      case 'pending':
        return 'warning';
      case 'canceled':
        return 'error';
      default:
        return 'warning';
    }
  };

  const getStatusReservaLabel = (value) => {
    switch (value) {
      case 'confirmed':
        return 'Confirmada';
      case 'created':
        return 'Criada';
      case 'paid':
        return 'Confirmada';
      case 'expired':
        return 'Expirada';
      case 'canceled':
        return 'Cancelada';
      default:
        return 'Pendente';
    }
  };

  const getStatusLabel = (value) => {
    switch (value) {
      case 'confirmed':
        return 'Confirmado';
      case 'created':
        return 'Pendente';
      case 'paid':
        return 'Confirmado';
      case 'expired':
        return 'Expirado';
      case 'canceled':
        return 'Cancelado';
      default:
        return 'Pendente';
    }
  };

  const getPaymentTypeLabel = (value) => {
    switch (value) {
      case 'credit-card':
        return 'Cartão de Crédito';
      case 'pix':
        return 'Pix';
      case 'vp-card':
        return 'VPCard';
      case 'pix-scheduled':
        return 'Pix Agendado';
      default:
        return 'Faturamento';
    }
  };

  async function loadTravelers() {
    api
      .get(`/bookings/${id}/travelers`)
      .then((response) => {
        if (response.data.length > 0) {
          setTableBody(response.data);
        }
      })
      .catch((error) => alert(error));
  }

  const handleOpenCancelModal = (status, valueFee, valueRefund) => {
    setReservationStatus(status);
    setReservationValueFee(valueFee);
    setReservationValueRefund(valueRefund);
    setIsCancelModalOpen(true);
  };

  const handleCloseCancelModal = () => {
    setIsCancelModalOpen(false);
    setIsConfirmModalOpen(false);
  };

  const handleCancelReservation = async () => {
    try {
      await postBookingCancellationRequest(
        reservationValueFee,
        reservationValueRefund,
      );
    } catch (error) {
      console.error('Erro ao cancelar a reserva:', error);
    }
  };

  return (
    <>
      <div className="w-full">
        <div className="flex flex-col">
          <PageHeader
            paths={[
              { label: 'Home', link: PagePath.Home },
              { label: 'Reservas', link: PagePath.Reservas },
              { label: 'Gestão de reservas', link: PagePath.ReservasGerenciar },
              { label: 'Detalhes da reserva', link: PagePath.DetalhesReserva },
            ]}
            title="Detalhes da reserva"
            button={cancelRule ? 'Cancelar reserva' : ''}
            buttonClick={() =>
              handleOpenCancelModal(
                cancelRule.status,
                cancelRule.valueFee,
                cancelRule.valueRefund,
              )
            }
            buttonGhost={true}
          />

          <div className="flex flex-col gap-4">
            {/* Informações da reserva */}
            {booking && (
              <Container>
                <div className="flex w-full items-center justify-between pb-4">
                  <div className="flex flex-col gap-1">
                    <h3 className="text-heading-2 text-primary">
                      Reserva {booking.code}
                    </h3>
                    <span className="text-heading-4 text-neutral-800">
                      Realizada: {dayjs(booking.dueAt).format('DD/MM/YYYY')}
                    </span>
                  </div>
                  <Tag
                    value={getStatusReservaLabel(booking.status)}
                    type={getStatusType(booking.status)}
                  />
                </div>

                <div className="w-full border-x-0 border-b-0 border-t border-solid border-neutral-300 pt-4">
                  <span className="text-heading-3 text-neutral-800">
                    Empresa: {booking.agency.legalName || ''}
                  </span>
                </div>
              </Container>
            )}

            {/* Route card detailed */}
            <Container className="border-none">
              <h1 className="m-0 text-heading-1 text-primary">
                Detalhes do voo
              </h1>
              {flightDetail && (
                <RouteCardDetailed
                  key={flightDetail.id}
                  baggage={flightDetail.baggage}
                  airports={airports}
                  departureOperator={flightDetail.outbound.fleet.airline.name}
                  departureFlight={flightDetail.outbound.flightNumber}
                  departureCompanyLogo={
                    flightDetail.outbound.fleet.airline.logo
                  }
                  departureOrigin={flightDetail.outbound.airport.origin.iata}
                  departureOriginTime={flightDetail.outbound.departure}
                  departureOriginDate={flightDetail.outboundDate}
                  departureDestination={
                    flightDetail.outbound.airport.destination.iata
                  }
                  departureDestinationTime={flightDetail.outbound.arrival}
                  departureDestinationDate={flightDetail.outbound.arrival}
                  returnOperator={flightDetail.return.fleet.airline.name}
                  returnFlight={flightDetail.return.flightNumber}
                  returnCompanyLogo={flightDetail.return.fleet.airline.logo}
                  returnOrigin={flightDetail.return.airport.origin.iata}
                  returnOriginTime={flightDetail.return.departure}
                  returnOriginDate={flightDetail.returnDate}
                  returnDestination={
                    flightDetail.return.airport.destination.iata
                  }
                  returnDestinationTime={flightDetail.return.departure}
                  returnDestinationDate={flightDetail.return.departure}
                />
              )}
            </Container>

            {/* PASSAGEIROS  */}
            <Container className="border-none">
              <h1 className="mb-4 pb-3 text-heading-1 text-primary">
                Passageiros
              </h1>
              <hr />
              <Container className="pb-0">
                <div className="flex items-end justify-start gap-6 border-x-0 border-b border-t-0 border-solid border-primary py-5">
                  {tableHeadings.map((heading, index) => {
                    const isLastItem = index === tableHeadings.length - 1;
                    return (
                      <span
                        className={`flex justify-start p-0 text-heading-3 text-primary ${isLastItem ? 'w-9' : 'w-full'}`}
                        key={heading}
                      >
                        {heading}
                      </span>
                    );
                  })}
                </div>
                <div className="divide-x-0 divide-y divide-solid divide-neutral-300">
                  {tableBody.map((item) => (
                    <div
                      className="flex cursor-pointer items-center gap-6 divide-y px-3 py-4 hover:bg-secondary-100"
                      key={item.cpf}
                      onClick={() => {
                        setOpenModalPassenger(true);
                        setModalPassenger(item);
                      }}
                    >
                      <span className="flex w-full justify-start text-body text-sm text-neutral-800">
                        {`${item.firstName} ${item.lastName}`}
                      </span>
                      <span className="flex w-full justify-start text-body text-sm text-neutral-800">
                        {item.birthDate}
                      </span>
                      <span className="flex w-full justify-start text-body text-sm text-neutral-800">
                        {formatDocumentCPF(item.cpf)}
                      </span>
                      <span className="flex w-full justify-start text-body text-sm text-neutral-800">
                        {item.locator}
                      </span>
                      <span className="flex w-full justify-start text-body text-sm font-bold text-price">
                        {formatCurrency(item.value)}
                      </span>
                      <span
                        className="flex w-9 justify-start text-body text-neutral-800"
                        onClick={() => {
                          setOpenModalPassenger(true);
                          setModalPassenger(item);
                        }}
                      >
                        <BiEditAlt
                          className="cursor-pointer text-secondary"
                          size={24}
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </Container>
            </Container>

            {/* PAGAMENTO */}
            {paymentInfo && (
              <Container>
                <div className="flex w-full items-center justify-between pb-4">
                  <div className="flex flex-col gap-1">
                    <h3 className="text-heading-2 text-primary">Pagamento</h3>
                    <span className="text-heading-4 text-neutral-800">
                      Categoria: Reserva
                    </span>
                  </div>
                  <Tag
                    value={getStatusLabel(paymentInfo.status)}
                    type={getStatusType(paymentInfo.status)}
                  />
                </div>

                <div className="flex w-full flex-col gap-2 border-x-0 border-b-0 border-t border-solid border-neutral-300 pt-4">
                  <span className="text-heading-4  text-neutral-800">
                    <strong className="text-heading-3">
                      Forma de pagamento:
                    </strong>{' '}
                    {getPaymentTypeLabel(paymentInfo.paymentMethod)}
                  </span>
                  {paymentInfo.status === 'paid' && (
                    <span className="text-heading-4  text-neutral-800">
                      <strong className="text-heading-3">
                        Pagamento realizado:
                      </strong>{' '}
                      {format(parseISO(paymentInfo.paymentDate), 'dd/MM/yyyy')}
                    </span>
                  )}
                </div>
                <Container className="mt-5 flex flex-col">
                  {paymentInfo.peopleDetails.map((item) => {
                    const labelPersonType = (p) => {
                      switch (p) {
                        case 'ADT':
                          return 'adultos';
                        case 'CHD':
                          return 'criança';
                        default:
                          return 'bebês';
                      }
                    };
                    return (
                      <span
                        className="text-body text-neutral-800"
                        key={item.personType}
                      >
                        {item.quantity} {labelPersonType(item.personType)}:{' '}
                        <strong className="text-body font-bold text-price">
                          {formatCurrency(item.totalAmount, true)}
                        </strong>
                      </span>
                    );
                  })}
                  <span className="text-body text-neutral-800">
                    Impostos, taxas e encargos:{' '}
                    <strong className="text-body font-bold text-price">
                      {formatCurrency(paymentInfo.taxAmount, true)}
                    </strong>
                  </span>
                  {getPaymentTypeLabel(paymentInfo.paymentMethod) !==
                    'Faturamento' && (
                    <span className="text-body text-neutral-800">
                      Acréscimos do juros do parcelamento:{' '}
                      <strong className="text-body font-bold text-price">
                        {formatCurrency(paymentInfo.interestrate, true)}
                      </strong>
                    </span>
                  )}

                  <hr />
                  <span className="text-heading-2 text-neutral-800">
                    Valor total:{' '}
                    <strong className="text-heading-2 font-bold text-price">
                      {formatCurrency(paymentInfo.totalAmount, true)}
                    </strong>
                  </span>
                </Container>
              </Container>
            )}
            <ButtonV2
              ghost
              size="large"
              className="w-[200px]"
              onClick={navigateGestaoReserva}
            >
              <ButtonV2.Text>Voltar</ButtonV2.Text>
            </ButtonV2>

            {openModalPassenger && (
              <ModalPassengerInfo
                bookingId={id}
                item={modalPassenger}
                departureDate={`${flightDetail.outboundDate}T${flightDetail.outbound.departure}`}
                onClose={() => {
                  setOpenModalPassenger(false);
                  setModalPassenger({});
                }}
                onUpdate={() => {
                  loadTravelers();
                }}
              />
            )}
          </div>
        </div>
      </div>

      {isCancelModalOpen && (
        <ModalCancelReservation
          open={isCancelModalOpen}
          handleCancel={handleCancelReservation}
          handleClose={handleCloseCancelModal}
          status={reservationStatus}
          valueFee={reservationValueFee}
          valueRefund={reservationValueRefund}
        />
      )}

      {isConfirmModalOpen && (
        <ModalConfirmCancelReservation
          open={isConfirmModalOpen}
          handleClose={handleCloseCancelModal}
          status={reservationStatus}
          bookingCode={booking.code}
        />
      )}
    </>
  );
}
