import { useEffect, useRef, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { Button } from '../../components/button';
import { SearchReservationTopNavBar } from '../../components/search-reservation-topnavbar';
import {
  UserMenu,
  UserMenuInfos,
  UserMenuOptions,
} from '../../components/user-menu';
import { ROLES } from '../../data';
import { menu } from '../../data/menu.data';
import { useAuthentication } from '../../stores/authentication';
import { styles } from './styles';

export function Menu({ isMenuOpen, handleMenuClick }) {
  const [isSubMenuOpen, setIsSubMenuOpen] = useState('');
  const { session } = useAuthentication();
  const menuRef = useRef(null);

  function handleSubMenuClick(menuId) {
    if (isSubMenuOpen === menuId) {
      setIsSubMenuOpen('');
      return;
    }
    setIsSubMenuOpen(menuId);
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsSubMenuOpen('');
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const hasRole = (userRole, allowedRoles) => {
    return allowedRoles.includes(ROLES[userRole]);
  };

  const menuParsed = menu
    .filter((item) => item?.isActive !== false)
    ?.filter(
      (subItem) =>
        subItem?.permission?.includes('all') ||
        subItem?.permission?.includes(ROLES?.[session.role]),
    )
    .map((item) => {
      const items = item?.items
        ?.filter((subItem) => subItem?.isActive !== false)
        ?.map((subItem) => {
          const subItems = subItem?.items?.filter(
            (subSubItem) => subSubItem?.isActive !== false,
          );
          return {
            ...subItem,
            items: subItems,
          };
        });
      return {
        ...item,
        items,
      };
    });

  const openSuportChat = (subItem) => {
    if (subItem.id === 'chat') {
      window.FreshworksWidget('open', 'ticketForm');
    }
  };

  return (
    <div
      className={styles.container({
        isMenuOpen,
      })}
      ref={menuRef}
    >
      <div className={styles.userInfoContainer()}>
        <UserMenuInfos />
      </div>
      <div className={styles.searchContainer()}>
        <SearchReservationTopNavBar handleMenuClick={handleMenuClick} />
      </div>
      <div className={styles.menuContainer()}>
        <div className={styles.menuButtonAndOptions()}>
          <div className={styles.menuButtonContainer()}>
            <Link
              to="/nova-reserva"
              onClick={() => {
                handleMenuClick();
                handleSubMenuClick('');
              }}
            >
              <Button variant="small" label="Nova reserva" />
            </Link>
          </div>
          <div className={styles.menuOptionsContainer()}>
            <ul className={styles.menuOption()}>
              {menuParsed.map((item) => (
                <div
                  key={item.id}
                  className="lg:relative lg:flex lg:items-center"
                >
                  <li
                    className={styles.menuOptionItem({
                      isActive: isSubMenuOpen === item.id,
                    })}
                    role="button"
                    onClick={() => handleSubMenuClick(item.id)}
                  >
                    {item.title}
                    {isSubMenuOpen === item.id ? (
                      <IoIosArrowUp />
                    ) : (
                      <IoIosArrowDown />
                    )}
                  </li>
                  {item?.items && isSubMenuOpen === item.id && (
                    <ul
                      className={styles.menuSubOption({
                        hasSubItems: item?.items?.some(
                          (subItem) => subItem?.items,
                        ),
                      })}
                    >
                      {item?.items?.map((subItem) => (
                        <div key={subItem.id}>
                          {subItem?.items ? (
                            <li
                              className={styles.menuSubOptionItem({
                                hasSubItems: true,
                              })}
                            >
                              {subItem.title}
                            </li>
                          ) : subItem?.type === 'function' ? (
                            <div
                              className={styles.menuSubOptionItem()}
                              onClick={() => {
                                openSuportChat(subItem);
                                handleMenuClick();
                                handleSubMenuClick('');
                              }}
                            >
                              <li className={styles.menuSubOptionItemLi()}>
                                {subItem.title}
                              </li>
                            </div>
                          ) : (
                            <Link
                              to={subItem.href}
                              target={
                                subItem?.type === 'external' ? '_blank' : null
                              }
                              className={styles.menuSubOptionItem()}
                              onClick={() => {
                                handleMenuClick();
                                handleSubMenuClick('');
                              }}
                            >
                              <li className={styles.menuSubOptionItemLi()}>
                                {subItem.title}
                              </li>
                            </Link>
                          )}
                          {subItem?.items && (
                            <ul className={styles.menuSubSubOption()}>
                              {subItem?.items?.map((subSubItem) => (
                                <Link
                                  className={styles.menuSubSubOptionItem()}
                                  to={subSubItem.href}
                                  key={subSubItem.id}
                                  target={
                                    subItem?.type === 'external'
                                      ? '_blank'
                                      : null
                                  }
                                  onClick={() => {
                                    handleMenuClick();
                                    handleSubMenuClick('');
                                  }}
                                >
                                  <li
                                    className={styles.menuSubSubOptionItemLi()}
                                  >
                                    {subSubItem.title}
                                  </li>
                                </Link>
                              ))}
                            </ul>
                          )}
                        </div>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </ul>
          </div>
        </div>
        <div className={styles.menuUserDataContainer()}>
          <UserMenuOptions handleMenuClick={handleMenuClick} />
        </div>
        <UserMenu handleMenuClick={handleMenuClick} />
      </div>
    </div>
  );
}
