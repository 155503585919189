import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import dayjs from 'dayjs';
import { Alert } from '@components/alert';
import { ButtonV2 } from '@components/new-components/button-v2';
import PageHeader from '@components/page-header';
import RouteCardDetailed from '@components/route-card/RouteCardDetailed';
import Tag from '@components/Tag';
import { Container } from '@layout';
import api from '@services/api';
import { useAuthentication } from '@stores/authentication';
import { Role } from '@system/acl';
import {
  finesRefundAlertTexts,
  finesRefundPaymentMethods,
} from '@system/enums';
import { formatCurrency, formatDocumentCPF } from '@system/utils';
import { formatFullName } from '@system/utils/formatFullName';
import { PagePath } from '../../pages-config';
import ActionsDetailsModals from './Modais/ActionsDetailsModals';

export default function FinanceiroMultasReembolsosDetalhes() {
  const navigate = useNavigate();
  const [tableBody, setTableBody] = useState([]);
  const [booking, setBooking] = useState();
  const [paymentInfo, setPaymentInfo] = useState();
  const [flightDetail, setFlightDetail] = useState();
  const { session } = useAuthentication();
  const [cancellationRequest, setCancellationRequest] = useState({});
  const [airports, setAirports] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [alertData, setAlertData] = useState({});
  const [modalData, setModalData] = useState({
    type: '',
    action: '',
    value: null,
  });
  const tableHeadings = [
    'Nome',
    'Data de <br> nascimento',
    'CPF',
    'Localizador',
    'Valor',
    '',
  ];
  const { id } = useParams();

  useEffect(() => {
    loadAirports();
    loadCancellationRequests();
  }, []);

  useEffect(() => {
    loadBooking();
    loadTravelers();
    loadPaymentInfo();
  }, [cancellationRequest, airports]);

  useEffect(() => {
    loadCharter();
  }, [booking, airports]);

  const navigateFinanceiroMultaReembolso = () => {
    navigate(PagePath.FinanceiroMultasReembolsos);
  };

  async function loadCancellationRequests() {
    api.get(`/cancellation-requests/${id}`).then((response) => {
      if (response.data) {
        setCancellationRequest(response.data);
        setAlertData(
          finesRefundAlertTexts?.[response.data?.type]?.[
            response.data?.status
          ] ?? {},
        );
        if (
          response.data?.status === 'pending' &&
          response.data?.approvedAmount
        ) {
          setAlertData(finesRefundAlertTexts[response.data?.type]['negotiate']);
        }
      }
    });
  }

  async function loadAirports() {
    api.get(`airports`).then((response) => {
      if (response.data) {
        setAirports(response.data);
      }
    });
  }

  async function loadCharter() {
    if (booking && booking.charterId && airports)
      api.get(`/charters/${booking.charterId}`).then((response) => {
        if (response.data) {
          setFlightDetail(response.data);
        }
      });
  }

  async function loadPaymentInfo() {
    if (cancellationRequest && cancellationRequest.bookingId && airports)
      api
        .get(`/bookings/${cancellationRequest.bookingId}/paymentInfo`)
        .then((response) => {
          if (response.data) {
            setPaymentInfo(response.data);
          }
        })
        .catch((error) => alert(error));
  }

  async function loadBooking() {
    if (cancellationRequest && cancellationRequest.bookingId && airports)
      api
        .get(`/bookings/${cancellationRequest.bookingId}`)
        .then((response) => {
          if (response.data) {
            setBooking(response.data);
          }
        })
        .catch((error) => alert(error));
  }

  async function loadTravelers() {
    if (cancellationRequest && cancellationRequest.bookingId && airports)
      api
        .get(`/bookings/${cancellationRequest.bookingId}/travelers`)
        .then((response) => {
          if (response.data.length > 0) {
            setTableBody(response.data);
          }
        })
        .catch((error) => alert(error));
  }

  async function modalAction(type, payload) {
    if (type === 'inform') {
      await updatePaymentInfo(payload);
      return;
    }
    await updateCancellationRequest(payload);
  }

  async function updateCancellationRequest({ status, approvedAmount }) {
    api
      .patch(`/bookings/${cancellationRequest.bookingId}/cancel-rule`, {
        status,
        approvedAmount: Number(approvedAmount),
        cancellationRequestId: Number(id),
      })
      .catch((error) => alert(error))
      .then(() => {
        loadCancellationRequests();
        setOpenModal(false);
      });
  }

  async function updatePaymentInfo({ status, paymentDate, paymentMethod }) {
    console.log(status, paymentDate, paymentMethod);
    api
      .post(`/bookings/${cancellationRequest.id}/refund`, {
        status,
        payment_date: paymentDate,
        payment_method: paymentMethod,
        amount: cancellationRequest.amount,
      })
      .then(() => {
        loadCancellationRequests();
        setOpenModal(false);
      })
      .catch((error) => alert(error));
  }

  const getPaymentMethod = (value) => {
    switch (value) {
      case 'review':
        return 'warning';
      case 'pending':
        return 'warning';
      case 'canceled':
        return 'error';
      case 'confirmed':
        return 'success';
      case 'awaiting':
        return 'warning';
      default:
        return 'warning';
    }
  };

  const getStatusCancelledRequestType = (value) => {
    switch (value) {
      case 'review':
        return 'warning';
      case 'pending':
        return 'warning';
      case 'canceled':
        return 'error';
      case 'confirmed':
        return 'success';
      case 'awaiting':
        return 'warning';
      default:
        return 'warning';
    }
  };

  const getStatusCancelledRequestLabel = (value) => {
    switch (value) {
      case 'review':
        return 'Em análise';
      case 'pending':
        return 'Pendente';
      case 'canceled':
        return 'Cancelado';
      case 'confirmed':
        return 'Confirmado';
      case 'awaiting':
        return 'Aguardando pagamento';
      default:
        return 'Em análise';
    }
  };

  const getStatusReservaLabel = (value) => {
    switch (value) {
      case 'confirmed':
        return 'Confirmada';
      case 'created':
        return 'Criada';
      case 'paid':
        return 'Confirmada';
      case 'expired':
        return 'Expirada';
      case 'canceled':
        return 'Cancelada';
      default:
        return 'Pendente';
    }
  };

  const getPaymentTypeLabel = (value) => {
    switch (value) {
      case 'credit-card':
        return 'Cartão de Crédito';
      case 'pix':
        return 'Pix';
      case 'vp-card':
        return 'VPCard';
      case 'pix-scheduled':
        return 'Pix Agendado';
      default:
        return 'Faturamento';
    }
  };

  const getStatusType = (value) => {
    switch (value) {
      case 'confirmed':
        return 'success';
      case 'created':
        return 'info';
      case 'paid':
        return 'success';
      case 'expired':
        return 'error';
      case 'pending':
        return 'warning';
      case 'canceled':
        return 'error';
      default:
        return 'warning';
    }
  };

  const getStatusLabel = (value) => {
    switch (value) {
      case 'confirmed':
        return 'Confirmado';
      case 'created':
        return 'Pendente';
      case 'paid':
        return 'Confirmado';
      case 'expired':
        return 'Expirado';
      case 'canceled':
        return 'Cancelado';
      default:
        return 'Pendente';
    }
  };

  const formatDateTimeString = (date) => {
    return `${dayjs(cancellationRequest.requestDateTime).format(
      'DD/MM/YYYY',
    )} às ${dayjs(cancellationRequest.requestDateTime).format('HH:mm')}`;
  };

  const canManageCancellationRequest = () => {
    return (
      session.role == Role.Administrador || session.role == Role.Financeiro
    );
  };

  const canViewCancellationRequest = () => {
    return (
      session.role == Role.Comprador ||
      session.role == Role.CompradorAdministrador
    );
  };

  const handleCheckout = () => {
    const { approvedAmount, amount } = cancellationRequest;
    navigate(PagePath.CheckoutV2, {
      state: {
        charterId: booking.charterId,
        value: approvedAmount ?? amount,
        bookingId: booking.id,
        referenceId: id,
      },
    });
  };

  return (
    <div className="w-full">
      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Financeiro', link: PagePath.Financeiro },
          {
            label: 'Multas e Reembolsos',
            link: PagePath.FinanceiroMultasReembolsos,
          },
          {
            label: 'Detalhes Multas e reembolsos',
            link: PagePath.DetalhesMultasReembolsos,
          },
        ]}
        title="Multas e reembolsos"
      />
      <div className="flex flex-col">
        <div className="flex flex-col gap-4">
          {/* Informações da reserva */}
          {booking && (
            <Container>
              <div className="flex w-full items-center justify-between pb-4">
                <div className="flex flex-col gap-1">
                  <h2 className="m-0 text-heading-2 text-primary">
                    Reserva {booking.code}
                  </h2>
                  <h4 className="m-0 text-heading-4 text-neutral-800">
                    Criada: {dayjs(booking.dueAt).format('DD/MM/YYYY')} |
                    Cancelada: {formatDateTimeString()} por{' '}
                    {formatFullName(cancellationRequest.requestUser)}
                  </h4>
                </div>
                <Tag
                  value={getStatusReservaLabel(booking.status)}
                  type={getStatusType(booking.status)}
                />
              </div>

              <div className="w-full border-x-0 border-b-0 border-t border-solid border-neutral-300 pt-4">
                <span className="text-heading-3 text-neutral-800">
                  Empresa: {booking.agency.legalName || ''}
                </span>
              </div>
            </Container>
          )}

          {/* Card de cancelamento */}
          <Container className="flex flex-col border-none p-4">
            <div className="flex w-full items-center justify-between border-0 border-b border-solid border-neutral-300 pb-4">
              <div className="flex flex-col gap-1">
                <h2 className="text-heading-2 text-primary">Pagamento</h2>
                <h4 className="m-0 text-heading-4 text-neutral-800">
                  Categoria: Cancelamento
                </h4>
              </div>
              <Tag
                value={getStatusCancelledRequestLabel(
                  cancellationRequest.status,
                )}
                type={getStatusCancelledRequestType(cancellationRequest.status)}
              />
            </div>
            {/* Ações para revisão do card */}
            {cancellationRequest.status === 'review' &&
              canManageCancellationRequest() && (
                <div className="mt-5 flex flex-row justify-end gap-4">
                  <ButtonV2
                    ghost
                    size="medium"
                    onClick={() => {
                      setOpenModal(true);
                      setModalData({
                        type: cancellationRequest.type,
                        action: 'canceled',
                        value: cancellationRequest.amount,
                      });
                    }}
                  >
                    <ButtonV2.Text>
                      Cancelar
                      {cancellationRequest.type === 'refund'
                        ? ' reembolso'
                        : ' multa'}
                    </ButtonV2.Text>
                  </ButtonV2>
                  <ButtonV2
                    ghost
                    size="medium"
                    onClick={() => {
                      setOpenModal(true);
                      setModalData({
                        type: cancellationRequest.type,
                        action: 'deal',
                        value: cancellationRequest.amount,
                      });
                    }}
                  >
                    <ButtonV2.Text>
                      Negociar
                      {cancellationRequest.type === 'refund'
                        ? ' reembolso'
                        : ' multa'}
                    </ButtonV2.Text>
                  </ButtonV2>
                  <ButtonV2
                    ghost
                    size="medium"
                    onClick={() => {
                      setOpenModal(true);
                      setModalData({
                        type: cancellationRequest.type,
                        action: 'approve',
                        value: cancellationRequest.amount,
                      });
                    }}
                  >
                    <ButtonV2.Text>
                      Aprovar
                      {cancellationRequest.type === 'refund'
                        ? ' reembolso'
                        : ' multa'}
                    </ButtonV2.Text>
                  </ButtonV2>
                </div>
              )}

            {/* Alert para dados em cards que não são admin */}
            {canViewCancellationRequest() &&
              cancellationRequest &&
              !['confirmed', 'awaiting'].includes(
                cancellationRequest.status,
              ) && (
                <div className="mt-5">
                  <Alert type={alertData?.type} className="py-5">
                    <div className="flex flex-col">
                      <span className="text-small text-neutral-800">
                        {alertData?.text}
                      </span>
                    </div>
                  </Alert>
                </div>
              )}

            {/* Botão de informar pagamento */}
            {cancellationRequest.type === 'refund' &&
              cancellationRequest.status === 'pending' &&
              canManageCancellationRequest() && (
                <div className="mt-5 flex flex-row justify-end gap-4">
                  <ButtonV2
                    ghost
                    size="medium"
                    onClick={() => {
                      setOpenModal(true);
                      setModalData({
                        type: cancellationRequest.type,
                        action: 'inform',
                        value: cancellationRequest.amount,
                      });
                    }}
                  >
                    <ButtonV2.Text>Informar pagamento</ButtonV2.Text>
                  </ButtonV2>
                </div>
              )}

            {/* Botão para realizar pagamento */}
            {cancellationRequest.type === 'fines' &&
              cancellationRequest.status === 'pending' &&
              canViewCancellationRequest() && (
                <div className="mt-5 flex flex-row justify-end gap-4">
                  <ButtonV2 ghost size="medium" onClick={handleCheckout}>
                    <ButtonV2.Text>Realizar pagamento</ButtonV2.Text>
                  </ButtonV2>
                </div>
              )}

            {/* Dados da forma de pagamento */}
            {cancellationRequest.status == 'confirmed' &&
              cancellationRequest?.cancellationPayment && (
                <div className="mt-4 text-base text-primary">
                  <p className="mb-2.5">
                    <span className="font-semibold">Forma de pagamento: </span>
                    {
                      finesRefundPaymentMethods[
                        cancellationRequest?.cancellationPayment?.payment_method
                      ]
                    }
                  </p>
                  <p className="m-0">
                    <span className="font-semibold">Pagamento realizado: </span>
                    {format(
                      cancellationRequest?.cancellationPayment?.payment_date,
                      'dd/MM/yy',
                    )}
                  </p>
                </div>
              )}

            <div className="mt-5 w-full rounded-md border border-solid border-neutral-300 p-5">
              {/* Dados de alteração de valores */}
              {cancellationRequest?.approvedAmount &&
                cancellationRequest?.approvedAmount !=
                  cancellationRequest.amount &&
                cancellationRequest.status != 'confirmed' && (
                  <div className="border-bottom mb-4 border-0 border-solid border-neutral-600">
                    <p className="text-sm">
                      {' '}
                      Valor inicial{' '}
                      {cancellationRequest.type === 'refund'
                        ? 'do reembolso'
                        : 'da multa de cancelamento'}
                      :{' '}
                      <span className="font-semibold text-price">
                        {' '}
                        {formatCurrency(cancellationRequest.amount)}{' '}
                      </span>
                    </p>
                    <p className="text-sm">
                      {' '}
                      Valor negociado{' '}
                      {cancellationRequest.type === 'refund'
                        ? 'do reembolso'
                        : 'da multa de cancelamento'}
                      :{' '}
                      <span className="font-semibold text-price">
                        {' '}
                        {formatCurrency(
                          cancellationRequest.approvedAmount,
                        )}{' '}
                      </span>
                    </p>
                  </div>
                )}
              <h2 className="m-0 text-heading-2 text-neutral-800">
                Valor{' '}
                {cancellationRequest.type === 'refund'
                  ? 'do reembolso'
                  : 'da multa de cancelamento'}
                :{' '}
                <span className="text-price">
                  {' '}
                  {cancellationRequest.approvedAmount
                    ? formatCurrency(cancellationRequest.approvedAmount)
                    : formatCurrency(cancellationRequest.amount)}{' '}
                </span>
              </h2>
            </div>
          </Container>

          {/* Route card detailed */}
          <Container className="border-none p-4">
            <h2 className="mb-3 border-x-0 border-b border-t-0 border-solid border-neutral-300 pb-3 text-heading-2 text-primary">
              Detalhes do voo
            </h2>
            {flightDetail && (
              <RouteCardDetailed
                key={flightDetail.id}
                baggage={flightDetail.baggage}
                airports={airports}
                departureOperator={flightDetail.outbound.fleet.airline.name}
                departureFlight={flightDetail.outbound.flightNumber}
                departureCompanyLogo={flightDetail.outbound.fleet.airline.logo}
                departureOrigin={flightDetail.outbound.airport.origin.iata}
                departureOriginTime={flightDetail.outbound.departure}
                departureOriginDate={flightDetail.outboundDate}
                departureDestination={
                  flightDetail.outbound.airport.destination.iata
                }
                departureDestinationTime={flightDetail.outbound.arrival}
                departureDestinationDate={flightDetail.outbound.arrival}
                returnOperator={flightDetail.return.fleet.airline.name}
                returnFlight={flightDetail.return.flightNumber}
                returnCompanyLogo={flightDetail.return.fleet.airline.logo}
                returnOrigin={flightDetail.return.airport.origin.iata}
                returnOriginTime={flightDetail.return.departure}
                returnOriginDate={flightDetail.returnDate}
                returnDestination={flightDetail.return.airport.destination.iata}
                returnDestinationTime={flightDetail.return.departure}
                returnDestinationDate={flightDetail.return.departure}
                isReservationDetailsCard={true}
              />
            )}
          </Container>

          {/* PASSAGEIROS  */}
          <Container className="border-none p-4">
            <h3 className="text-heading-2 text-primary">Passageiros</h3>
            <hr />
            <Container className="pb-0">
              <div className="flex items-end justify-start gap-6 border-x-0 border-b border-t-0 border-solid border-primary px-3 py-5">
                {tableHeadings.map((heading, index) => {
                  const isLastItem = index === tableHeadings.length - 1;
                  return (
                    <span
                      className={`flex justify-start p-0 text-sm font-semibold text-primary ${isLastItem ? 'w-0' : 'w-full'}`}
                      key={heading}
                      dangerouslySetInnerHTML={{ __html: heading }}
                    />
                  );
                })}
              </div>
              <div className="divide-x-0 divide-y divide-solid divide-neutral-300">
                {tableBody.map((item) => (
                  <div
                    className="flex items-center gap-6 divide-y px-3 py-4"
                    key={item.cpf}
                  >
                    <span className="w-full justify-start pr-2 text-body text-sm text-neutral-800">
                      {`${item.firstName} ${item.lastName}`}
                    </span>
                    <span className="w-full justify-start text-body text-sm text-neutral-800">
                      <span>{item.birthDate}</span>
                    </span>
                    <span className="w-full justify-start text-body text-sm text-neutral-800">
                      <span>{formatDocumentCPF(item.cpf)}</span>
                    </span>
                    <span className="w-full justify-start text-body text-sm text-neutral-800">
                      <span>{item.locator}</span>
                    </span>
                    <span className="w-full justify-start  text-body text-sm font-bold text-price">
                      <span>{formatCurrency(item.value)}</span>
                    </span>
                    <span className="w-0">&nbsp;</span>
                  </div>
                ))}
              </div>
            </Container>
          </Container>

          {/* PAGAMENTO */}
          {paymentInfo && (
            <Container className="border-none p-4">
              <div className="flex w-full items-center justify-between pb-4">
                <div className="flex flex-col gap-1">
                  <h3 className="text-heading-2 text-primary">Pagamento</h3>
                  <span className="text-heading-4 text-neutral-800">
                    Categoria: Reserva
                  </span>
                </div>
                <Tag
                  value={getStatusLabel(paymentInfo.status)}
                  type={getStatusType(paymentInfo.status)}
                />
              </div>

              <div className="flex w-full flex-col gap-2 border-x-0 border-b-0 border-t border-solid border-neutral-300 pt-4">
                <span className="text-heading-4  text-neutral-800">
                  <strong className="text-heading-3">
                    Forma de pagamento:
                  </strong>{' '}
                  {getPaymentTypeLabel(paymentInfo.paymentMethod)}
                </span>
                {paymentInfo.status === 'paid' && (
                  <span className="text-heading-4  text-neutral-800">
                    <strong className="text-heading-3">
                      Pagamento realizado:
                    </strong>{' '}
                    {format(parseISO(paymentInfo.paymentDate), 'dd/MM/yyyy')}
                  </span>
                )}
              </div>
              <Container className="mt-5 flex flex-col">
                {paymentInfo.peopleDetails.map((item) => {
                  const labelPersonType = (p) => {
                    switch (p) {
                      case 'ADT':
                        return 'adultos';
                      case 'CHD':
                        return 'criança';
                      default:
                        return 'bebês';
                    }
                  };
                  return (
                    <span
                      className="text-body text-neutral-800"
                      key={item.personType}
                    >
                      {item.quantity} {labelPersonType(item.personType)}:{' '}
                      <strong className="text-body font-semibold text-price">
                        {formatCurrency(item.totalAmount)}
                      </strong>
                    </span>
                  );
                })}

                <span className="text-body text-neutral-800">
                  Impostos, taxas e encargos:{' '}
                  <strong className="text-body font-semibold text-price">
                    {formatCurrency(paymentInfo.taxAmount)}
                  </strong>
                </span>
                <span className="text-body text-neutral-800">
                  Acréscimos do juros do parcelamento:{' '}
                  <strong className="text-body font-bold text-price">
                    {formatCurrency(paymentInfo.interestrate)}
                  </strong>
                </span>
                <hr />
                <span className="text-heading-2 text-neutral-800">
                  Valor total:{' '}
                  <strong className="text-heading-2 font-semibold text-price">
                    {formatCurrency(paymentInfo.totalAmount)}
                  </strong>
                </span>
              </Container>
            </Container>
          )}
          {openModal && (
            <ActionsDetailsModals
              openModal={true}
              data={modalData}
              onClose={() => {
                setOpenModal(false);
              }}
              onAction={(e) => {
                modalAction(modalData.action, e);
              }}
            />
          )}
          <ButtonV2
            ghost
            size="large"
            className="w-[200px]"
            onClick={navigateFinanceiroMultaReembolso}
          >
            <ButtonV2.Text>Voltar</ButtonV2.Text>
          </ButtonV2>
        </div>
      </div>
    </div>
  );
}
