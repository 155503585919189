import api from '@services/api';
import { useEffect, useState } from 'react';
import { BiSolidChevronRight, BiTrash } from 'react-icons/bi';
import {
  BsDashCircleFill,
  BsFillPlusCircleFill,
  BsToggleOff,
  BsToggleOn,
} from 'react-icons/bs';
import { GiPlaneWing } from 'react-icons/gi';
import { MdOutlineHome, MdOutlineStar } from 'react-icons/md';
import { TfiLayoutWidthDefault } from 'react-icons/tfi';
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoadingAnimation from '../components/LoadingAnimation';
import { PagePath } from '../pages-config';
import PageHeader from '@components/page-header';

export default function AereoFrotaAssentos() {
  const navigate = useNavigate();
  const [typeAlert, setTypeAlert] = useState('');
  const [titleAlert, settitleAlert] = useState('');
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  const [openModal, setOpenModal] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [updateLabelColumn, setupdateLabelColumn] = useState('');
  const [updateLabelColumnClass, setupdateLabelColumnClass] = useState('');
  const [upadeFileira, setupdateFileira] = useState('');
  const [updateTipoFileira, setupdateTipoFileira] = useState('');
  const [updateIdFileira, setupdateIdFileira] = useState('');
  const [selectClass, setSelectClass] = useState('primeiraClasse');

  const [minFileiras] = useState([
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
    '.',
  ]);
  const [distancia, setDistancia] = useState('');
  const [largura, setLargura] = useState('');
  const [angulo, setAngulo] = useState('');

  const [selectCorredor, setSelectCorredor] = useState('');
  const [selectFileira, setSelectFileira] = useState('');

  const [newLabel, setNewLabel] = useState('');
  const [content, setContent] = useState({});

  const [servicoBordoData, setServicoBordoData] = useState([]);
  const [assentoClasseData, setAssentoClasseData] = useState([]);

  useEffect(() => {
    carregaPagina();
  }, []);

  async function carregaPagina() {
    setLoading(true);

    try {
      const fleetResp = await api.get(`/fleet/${id}`);
      if (fleetResp) {
        setContent(fleetResp.data);
      } else {
        setTypeAlert('alert-danger');
        settitleAlert('Registro não encontrado!');
        setTimeout(() => setTypeAlert(''), 3000);
      }
    } catch (error) {
      alert(error);
    }

    try {
      const onboardResp = await api.get(`/onboard-services/`);
      if (onboardResp) {
        setServicoBordoData(onboardResp.data);
      }
    } catch (error) {
      alert(error);
    }

    try {
      const seatResp = await api.get('/seat/features');
      if (seatResp) {
        setAssentoClasseData(seatResp.data);
      }
    } catch (error) {
      alert(error);
    }
    setLoading(false);
  }

  function changeFileira(e, t, i) {
    setOpenModal2(true);
    setupdateFileira(e);
    setupdateTipoFileira(t);
    setupdateIdFileira(i);
  }

  function updateLine(e, x) {
    setOpenModal2(false);
    setLoading(true);
    setTimeout(function () {
      let values = {
        seatType: x,
      };
      if (x == 'fileira') {
        values.type = null;
      }
      api
        .patch(`/seat/category/${content?.id}/${e}`, values)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            carregaPagina();
          } else {
            setTypeAlert('alert-danger');
            settitleAlert('Erro na Gravação!');
            setTimeout(() => setTypeAlert(''), 3000);
          }
        })
        .catch((error) => alert(error));
      setLoading(false);
    }, 1000);
  }

  function delColuna() {
    setLoading(true);
    const category = makeCategoryKeyName(selectClass);
    if (content?.airplaneSeats[category]?.column.length <= 0) {
      setLoading(false);
      return;
    }
    const columnId =
      content?.airplaneSeats[category]?.column[
        content?.airplaneSeats[category]?.column.length - 1
      ].id;
    if (columnId) {
      api
        .delete(`/seat/category/${content?.id}/${columnId}`)
        .then((response) => {
          if (response.status === 200) {
            carregaPagina();
          } else {
            setLoading(false);
          }
        })
        .catch((error) => alert(error));
    }
  }

  function makeCategoryKeyName(category) {
    switch (category) {
      case 'primeiraClasse':
        return 'firstClass';
      case 'premium':
        return 'premium';
      case 'executiva':
        return 'executive';
      case 'economica':
        return 'economic';
    }
  }

  function insertColuna() {
    setLoading(true);
    let nextCol = 'A';
    console.log('selectClass', selectClass);
    const category = makeCategoryKeyName(selectClass);
    if (content?.airplaneSeats[category]?.column.length > 0) {
      let lastCol =
        content?.airplaneSeats[category]?.column[
          content?.airplaneSeats[category]?.column.length - 1
        ].column;
      nextCol = String.fromCharCode(lastCol.charCodeAt(0) + 1);
    }

    const values = {
      category: selectClass,
      column: nextCol,
    };

    api
      .post(`/seat/category/${content?.id}`, values)
      .then((response) => {
        if (response.status === 201) {
          carregaPagina();
        } else {
          setLoading(false);
        }
      })
      .catch((error) => alert(error));
  }

  function delFileira() {
    setLoading(true);

    const category = makeCategoryKeyName(selectClass);

    if (content?.airplaneSeats[category]?.row.length - 1 <= 0) {
      setLoading(false);
      return;
    }
    const rowId =
      content?.airplaneSeats[category]?.row[
        content?.airplaneSeats[category]?.row.length - 1
      ].id;
    if (rowId) {
      api
        .delete(`/seat/category/${content?.id}/${rowId}`)
        .then((response) => {
          if (response.status === 200) {
            carregaPagina();
          }
        })
        .catch((error) => alert(error));
    }
  }

  function insertFileira() {
    setLoading(true);

    let nextRow = 0;
    const category = makeCategoryKeyName(selectClass);
    if (content?.airplaneSeats[category]?.row?.length > 0) {
      let lastCol =
        content?.airplaneSeats[category]?.row[
          content?.airplaneSeats[category]?.row.length - 1
        ].row;
      nextRow = lastCol + 1;
    }

    const values = {
      category: selectClass,
      row: nextRow,
    };
    api
      .post(`/seat/category/${content?.id}`, values)
      .then((response) => {
        if (response.status === 201) {
          carregaPagina();
          setTimeout(() => setLoading(false), 5000);
        }
      })
      .catch((error) => alert(error));
  }

  function changeSelectClass(e) {
    setLoading(true);
    setSelectClass(e);

    carregaPagina();

    const key = makeCategoryKeyName(e);
    if (content?.aircraftFeature[key]) {
      setDistancia(content?.aircraftFeature[key].distance);
      setLargura(content?.aircraftFeature[key].width);
      setAngulo(content?.aircraftFeature[key].angle);
      setLoading(false);
    } else {
      setDistancia('');
      setLargura('');
      setAngulo('');
      setLoading(false);
    }
  }

  function totalizadorFileiras() {
    const sections = ['firstClass', 'premium', 'executive', 'economic'];
    return sections.reduce((total, section) => {
      const rowLength = content?.airplaneSeats?.[section]?.row?.length ?? 0;
      return total + rowLength;
    }, 0);
  }

  function totalizadorColunas() {
    const sections = ['firstClass', 'premium', 'executive', 'economic'];
    return sections.reduce((total, section) => {
      const columnLength =
        content?.airplaneSeats?.[section]?.column?.length ?? 0;
      return total + columnLength;
    }, 0);
  }

  function contFileiraPremium(x) {
    const y = content?.airplaneSeats?.firstClass?.row?.length ?? 0;
    return parseInt(x) + parseInt(y);
  }

  function contFileiraExecutiva(x) {
    const seats = content?.airplaneSeats;
    const a = seats?.firstClass?.row?.length ?? 0;
    const b = (seats?.premium?.row?.length ?? 1) - 1;
    return parseInt(x) + parseInt(a) + parseInt(b);
  }

  function contFileiraEconomica(x) {
    const seats = content?.airplaneSeats;
    const a = seats?.firstClass?.row?.length ?? 0;
    const b = (seats?.premium?.row?.length ?? 1) - 1;
    const c = (seats?.executive?.row?.length ?? 1) - 1;
    return parseInt(x) + parseInt(a) + parseInt(b) + parseInt(c);
  }

  function updateCaracteristicas(s_bordo, c_assentos) {
    const key = makeCategoryKeyName(selectClass);
    setLoading(true);
    if (content?.aircraftFeature[key]) {
      const aircraftFeatureId = content?.aircraftFeature[key].id;
      const values = {
        inFlightService: s_bordo,
        seatFeatures: c_assentos,
        distance: distancia,
        width: largura,
        angle: angulo,
      };
      api
        .patch(`/aircraft-features/${content?.id}/${aircraftFeatureId}`, values)
        .then((response) => {
          if (response.status === 200) {
            carregaPagina();
          }
        })
        .catch((error) => alert(error));
    } else {
      const values = {
        inFlightService: s_bordo,
        seatFeatures: c_assentos,
        distance: distancia,
        width: largura,
        angle: angulo,
        seatClass: selectClass,
      };

      api
        .post(`/aircraft-features/${content?.id}`, values)
        .then((response) => {
          if (response.status === 201) {
            carregaPagina();
          }
        })
        .catch((error) => alert(error));
    }
  }

  function gerarRota() {
    navigate(PagePath.AereoFrotaRotas.replace(':id', id));
  }

  function toggleServicoBordo(serviceId) {
    const categoryKey = makeCategoryKeyName(selectClass);
    const aircraftFeature = content?.aircraftFeature?.[categoryKey];

    if (!aircraftFeature) {
      updateCaracteristicas(String(serviceId), '', distancia, largura, angulo);
      return;
    }

    const currentServices = aircraftFeature.inFlightService
      ? aircraftFeature.inFlightService.split(',').filter(Boolean)
      : [];

    const serviceIdStr = String(serviceId);
    const isServiceEnabled = currentServices.includes(serviceIdStr);

    let updatedServices;
    if (isServiceEnabled) {
      updatedServices = currentServices.filter((id) => id !== serviceIdStr);
    } else {
      updatedServices = [...currentServices, serviceIdStr];
    }

    updateCaracteristicas(
      updatedServices.join(','),
      aircraftFeature.seatFeatures || '',
      distancia,
      largura,
      angulo,
    );
  }

  function checkServicoBordo(serviceId) {
    const key = makeCategoryKeyName(selectClass);
    if (!content?.aircraftFeature?.[key]?.inFlightService) {
      return false;
    }

    const services = content.aircraftFeature[key].inFlightService
      .split(',')
      .filter(Boolean);

    return services.includes(String(serviceId));
  }

  function checkAssentosClasse(x) {
    const key = makeCategoryKeyName(selectClass);
    let tt = content.aircraftFeature[key]?.seatFeatures?.split(',');
    if (tt) {
      let found = '';
      found = tt.find((element) => element == x);
      if (found) {
        return true;
      }
    }
  }

  function toggleAssentosClasse(x) {
    const key = makeCategoryKeyName(selectClass);

    if (!content?.airplaneSeats[key]) return;

    if (
      !Object.keys(content.aircraftFeature).length ||
      !content?.aircraftFeature[key]
    ) {
      updateCaracteristicas(
        content?.aircraftFeature[key]?.inFlightService || '',
        x,
      );
      return;
    }

    let tt = content.aircraftFeature[key]
      ? content.aircraftFeature[key]?.seatFeatures?.split(',')
      : [];
    let found = '';
    found = tt.find((element) => element == x);
    let newcaract = [];

    if (found) {
      let remove = x + ',';
      newcaract = content?.aircraftFeature[key]?.seatFeatures?.replace(
        remove,
        '',
      );
    } else {
      newcaract = x + ',' + content.aircraftFeature[key].seatFeatures;
    }
    updateCaracteristicas(
      content?.aircraftFeature[key]?.inFlightService || '',
      newcaract,
    );
  }

  function checkCorredor(x, y) {
    if (y == 'primeiraClasse') {
      for (let i = 0; i < content?.aisle?.firstClass?.length; ++i) {
        if (content?.aisle?.firstClass[i]?.column == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'premium') {
      for (let i = 0; i < content?.aisle?.premium?.length; ++i) {
        if (content?.aisle?.premium[i].column == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'executiva') {
      for (let i = 0; i < content?.aisle?.executive?.length; ++i) {
        if (content?.aisle?.executive[i].column == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'economica') {
      for (let i = 0; i < content?.aisle?.economic?.length; ++i) {
        if (content?.aisle?.economic[i].column == x) {
          return true;
        }
      }
      return false;
    }
  }

  function checkSaidaEmerg(x, y) {
    if (y == 'primeiraClasse') {
      for (let i = 0; i < content?.emergencyExit?.firstClass?.length; ++i) {
        if (content?.emergencyExit?.firstClass[i].row == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'premium') {
      for (let i = 0; i < content?.emergencyExit?.premium?.length; ++i) {
        if (content?.emergencyExit?.premium[i].row == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'executiva') {
      for (let i = 0; i < content?.emergencyExit?.executive?.length; ++i) {
        if (content?.emergencyExit?.executive[i].row == x) {
          return true;
        }
      }
      return false;
    }
    if (y == 'economica') {
      for (let i = 0; i < content?.emergencyExit?.economic?.length; ++i) {
        if (content?.emergencyExit?.economic[i].row == x) {
          return true;
        }
      }
      return false;
    }
  }

  function validaForm(e) {
    console.log(e);
    console.log('validaForm');
  }
  function delCorredor(id) {
    setLoading(true);
    api
      .delete(`aisle/${content?.id}/${id}`)
      .then(() => {
        carregaPagina();
      })
      .catch((error) => alert(error));
  }

  function insertCorredor() {
    setLoading(true);
    const key = makeCategoryKeyName(selectClass);
    if (content?.aisle[key]?.some((v) => v.column == selectCorredor)) {
      setLoading(false);
      return;
    } else {
      const value = {
        seatClass: selectClass,
        column: selectCorredor,
      };
      api
        .post(`/aisle/${content?.id}`, value)
        .then((response) => {
          if (response.status === 201) {
            setSelectCorredor('');
            carregaPagina();
          }
        })
        .catch((error) => alert(error));
    }
  }

  function checkSelectCorredor(x, y) {
    if (y == 'primeiraClasse') {
      if (
        content?.airplaneSeats?.firstClass.column[
          content?.airplaneSeats?.firstClass.column.length - 1
        ].column == x
      ) {
        return true;
      }
    }
    if (y == 'premium') {
      if (
        content?.airplaneSeats?.premium.column[
          content?.airplaneSeats?.premium.column.length - 1
        ].column == x
      ) {
        return true;
      }
    }
    if (y == 'executiva') {
      if (
        content?.airplaneSeats?.executive.column[
          content?.airplaneSeats?.executive.column.length - 1
        ].column == x
      ) {
        return true;
      }
    }
    if (y == 'economica') {
      if (
        content?.airplaneSeats?.economic.column[
          content?.airplaneSeats?.economic.column.length - 1
        ].column == x
      ) {
        return true;
      }
    }
    return false;
  }

  function soma(x, y, z, w) {
    return Number(x) + Number(y || 0) + Number(z || 0) + Number(w || 0);
  }

  function insertSaidaEmerg() {
    setLoading(true);
    const key = makeCategoryKeyName(selectClass);
    if (content?.emergencyExit[key]?.some((v) => v.row == selectFileira)) {
      setLoading(false);
      return;
    } else {
      const values = {
        seatClass: selectClass,
        row: selectFileira,
      };
      api
        .post(`emergency-exits/${content?.id}`, values)
        .then((response) => {
          if (response.status === 201) {
            setSelectFileira('');
            carregaPagina();
          }
        })
        .catch((error) => alert(error));
    }
  }

  function delSaidaEmerg(id) {
    setLoading(true);
    api
      .delete(`/emergency-exits/${content?.id}/${id}`)
      .then(() => {
        carregaPagina();
      })
      .catch((error) => alert(error));
  }

  function checkAssentoEspecial(x, y) {
    let totalPC = parseInt(content?.airplaneSeats?.firstClass?.row?.length);
    let ultimo = '';

    if (x == 'primeiraClasse') {
      for (let i = 0; i < content?.emergencyExit?.firstClass?.length; ++i) {
        if (content?.emergencyExit?.firstClass[i].row == y - 1) {
          return true;
        }
      }
      return false;
    }

    if (x == 'premium') {
      if (content?.emergencyExit?.premium?.length > 0) {
        let emergPC = parseInt(
          content?.emergencyExit?.premium[
            content?.emergencyExit?.firstClass?.length - 1
          ]?.row,
        );
        emergPC = emergPC + 1;
        if (totalPC == emergPC) {
          ultimo = emergPC;
        }
        if (ultimo == y) {
          return true;
        }
      }
      for (let i = 0; i < content?.emergencyExit?.premium?.length; ++i) {
        if (content?.emergencyExit?.premium[i].row == y - 1) {
          return true;
        }
      }
      return false;
    }

    if (x == 'executiva') {
      if (content?.emergencyExit?.premium?.length > 0) {
        let emergPR = parseInt(
          content?.emergencyExit?.premium[
            content?.emergencyExit?.premium.length - 1
          ].row,
        );
        emergPR = emergPR + 1;
        if (emergPR == y) {
          return true;
        }
      } else if (content?.emergencyExit?.firstClass?.length > 0) {
        let emergPR = parseInt(
          content?.emergencyExit?.firstClass[
            content?.emergencyExit?.firstClass.length - 1
          ].row,
        );
        emergPR = emergPR + 1;
        if (emergPR == emergPR) {
          ultimo = emergPR;
        }
        if (ultimo == y) {
          return true;
        }
      }
      for (let i = 0; i < content?.emergencyExit?.executive?.length; ++i) {
        if (content?.emergencyExit?.executive[i].row == y - 1) {
          return true;
        }
      }
      return false;
    }

    if (x == 'economica') {
      if (content?.emergencyExit?.executive?.length > 0) {
        let emergEX = parseInt(
          content?.emergencyExit?.executive[
            content?.emergencyExit?.executive?.length - 1
          ].row,
        );
        emergEX = emergEX + 1;
        if (emergEX == y) {
          return true;
        }
      } else if (content?.emergencyExit?.premium?.length > 0) {
        let emergEX = parseInt(
          content?.emergencyExit?.premium[
            content?.emergencyExit?.premium.length - 1
          ].row,
        );
        emergEX = emergEX + 1;
        if (emergEX == y) {
          return true;
        }
      } else if (content?.emergencyExit?.firstClass?.length > 0) {
        let emergEX = parseInt(
          content?.emergencyExit?.firstClass[
            content?.emergencyExit?.firstClass.length - 1
          ].row,
        );
        emergEX = emergEX + 1;
        if (emergEX == y) {
          return true;
        }
      }
      for (let i = 0; i < content?.emergencyExit?.economic?.length; ++i) {
        if (content?.emergencyExit?.economic[i].row == y - 1) {
          return true;
        }
      }
      return false;
    }
  }

  function changeLabelColumn(ncolumn, nclass) {
    setNewLabel(formatLabelColumn(ncolumn, nclass));
    setupdateLabelColumn(ncolumn);
    setupdateLabelColumnClass(nclass);
    setOpenModal(true);
  }
  function closeModal() {
    setOpenModal(false);
    setupdateLabelColumn('');
    setupdateLabelColumnClass('');
  }

  function formatLabelColumn(ncolumn, nclass) {
    return (
      content?.airplaneSeats[makeCategoryKeyName(nclass)]?.label ?? ncolumn
    );
  }

  return (
    <>
      {loading && <LoadingAnimation />}

      <div className={openModal ? 'bg_loading' : 'hide'}>
        <div className="globalModal text-center">
          <span className="closeModal" onClick={() => closeModal()}>
            ✕
          </span>
          <h5>Alterar Label da Coluna</h5>
          <h5>
            <b>{updateLabelColumn}</b>&nbsp;•&nbsp;
            {updateLabelColumnClass == '1'
              ? content?.airplaneSeats?.firstClass?.label
              : ''}
            {updateLabelColumnClass == '2'
              ? content?.airplaneSeats?.premium?.label
              : ''}
            {updateLabelColumnClass == '3'
              ? content?.airplaneSeats?.executive?.label
              : ''}
            {updateLabelColumnClass == '4'
              ? content?.airplaneSeats?.economic?.label
              : ''}
          </h5>
          <div className="row mt-4">
            <form onSubmit={(e) => validaForm(e)}>
              <div className="col-12 ">
                <input
                  type="tex"
                  className="uppercase"
                  value={newLabel}
                  placeholder="Novo Label"
                  maxLength="1"
                  onChange={(e) => setNewLabel(e.target.value)}
                ></input>
              </div>
              <div className="col-12 modalAssento">
                <div className="col-12 mt-3">
                  <button type="submit">Alterar</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className={openModal2 ? 'bg_loading' : 'hide'}>
        <div className="globalModal text-center">
          <span className="closeModal" onClick={() => setOpenModal2(false)}>
            ✕
          </span>

          <h4>
            Alterar fileira <strong>{upadeFileira}</strong>
          </h4>
          <div className="row mt-4">
            <div className="col-12 modalAssento">
              <div
                className={
                  updateTipoFileira != 'janela'
                    ? 'selectTipoAssento'
                    : 'normalTipoAssento'
                }
              >
                <TfiLayoutWidthDefault />
                <input
                  type="radio"
                  name="tipo_fileira"
                  value="fileira"
                  onChange={(e) => setupdateTipoFileira(e.target.value)}
                  checked={updateTipoFileira != 'asa' ? 'checked' : ''}
                />
                <span>Padrão</span>
              </div>
              <div
                className={
                  updateTipoFileira == 'janela'
                    ? 'selectTipoAssento'
                    : 'normalTipoAssento'
                }
              >
                <GiPlaneWing />
                <input
                  type="radio"
                  name="tipo_fileira"
                  value="asa"
                  onChange={(e) => setupdateTipoFileira(e.target.value)}
                  checked={updateTipoFileira == 'asa' ? 'checked' : ''}
                />
                <span>Asa</span>
              </div>
              <div className="col-12 mt-3">
                <button
                  type="button"
                  onClick={() => updateLine(updateIdFileira, updateTipoFileira)}
                >
                  Alterar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={typeAlert ? 'alert ' + typeAlert : 'hide'} role="alert">
        <h4 className={titleAlert ? '' : 'hide'}>{titleAlert}</h4>
        {/* {txtAlert} */}
      </div>

      <PageHeader
        paths={[
          { label: 'Home', link: PagePath.Home },
          { label: 'Configurações', link: PagePath.Admin },
          {
            label: 'Aéreo - Frotas',
            link: PagePath.AereoFrota,
          },
          {
            label: 'Aeronave',
            link: PagePath.AereoFrotaEditar.replace(':id', id),
          },
          {
            label: 'Mapa de assentos',
            link: PagePath.AereoFrotaAssentos,
          },
        ]}
        title="Mapa de Assentos"
      />

      <div className="contentAll">
        <div className="row">
          <div className={selectClass ? 'col-5 interCol' : 'hide'}>
            <div className="row  mt-4">
              <div className="col-12 titleAeronave">
                <img
                  className={content?.airline?.logo ? 'imgCia' : 'hide'}
                  src={content?.airline?.logo}
                />{' '}
                {content?.airline?.logo ? '' : content?.airline?.name}{' '}
                <h4>{content?.manufacture?.aircraftName}</h4>
              </div>

              <div className="col-12 mt-3 ">
                <ul className="nav nav-pills nav-justified tabAeronave">
                  <li
                    className="nav-item"
                    onClick={() => changeSelectClass('primeiraClasse')}
                  >
                    <span
                      className={
                        selectClass == 'primeiraClasse'
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      aria-current={
                        selectClass == 'primeiraClasse' ? 'page' : ''
                      }
                    >
                      {content?.airline?.firstClass}
                    </span>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => changeSelectClass('premium')}
                  >
                    <span
                      className={
                        selectClass == 'premium'
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      aria-current={selectClass == 'premium' ? 'page' : ''}
                    >
                      {content?.airline?.premium}
                    </span>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => changeSelectClass('executiva')}
                  >
                    <span
                      className={
                        selectClass == 'executiva'
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      aria-current={selectClass == 'executiva' ? 'page' : ''}
                    >
                      {content?.airline?.executive}
                    </span>
                  </li>
                  <li
                    className="nav-item"
                    onClick={() => changeSelectClass('economica')}
                  >
                    <span
                      className={
                        selectClass == 'economica'
                          ? 'nav-link active'
                          : 'nav-link'
                      }
                      aria-current={selectClass == 'economica' ? 'page' : ''}
                    >
                      {content?.airline?.economic}
                    </span>
                  </li>
                </ul>
              </div>

              <div className="col-12">
                {/*
                        <label>Categoria</label>
                        <h4>{selectClass}</h4>
                        <select name="select" value={selectClass} onChange={(e)=>changeSelectClass(e.target.value)}>
                          <option ></option>
                          <option value="primeiraClasse">Primeira Classe</option>
                          <option value="premium">Premium</option>
                          <option value="executiva">Executiva</option>
                          <option value="economica">Econômica</option>
                        </select>
                        */}
              </div>

              <div className={selectClass ? 'lineConfig mt-5' : 'hide'}>
                <div>Colunas</div>
                <button onClick={() => delColuna()} className="btnMorLess">
                  <BsDashCircleFill />
                </button>
                <h4 className={selectClass == 'primeiraClasse' ? '' : 'hide'}>
                  {content?.airplaneSeats?.firstClass?.column?.length ?? '0'}
                </h4>
                <h4 className={selectClass == 'premium' ? '' : 'hide'}>
                  {content?.airplaneSeats?.premium?.column?.length ?? '0'}
                </h4>
                <h4 className={selectClass == 'executiva' ? '' : 'hide'}>
                  {content?.airplaneSeats?.executive?.column?.length ?? '0'}
                </h4>
                <h4 className={selectClass == 'economica' ? '' : 'hide'}>
                  {content?.airplaneSeats?.economic?.column?.length ?? '0'}
                </h4>
                <button
                  onClick={() => insertColuna()}
                  className="btnMorLess"
                  title="Inserir"
                >
                  <BsFillPlusCircleFill />
                </button>
              </div>
              <div className={selectClass ? 'lineConfig' : 'hide'}>
                <div>Fileiras</div>
                <button onClick={() => delFileira()} className="btnMorLess">
                  <BsDashCircleFill />
                </button>
                <h4 className={selectClass == 'primeiraClasse' ? '' : 'hide'}>
                  {content?.airplaneSeats?.firstClass?.row?.length > 0
                    ? (content?.airplaneSeats?.firstClass?.row?.length ?? 1) - 1
                    : 0}
                </h4>
                <h4 className={selectClass == 'premium' ? '' : 'hide'}>
                  {content?.airplaneSeats?.premium?.row?.length > 0
                    ? (content?.airplaneSeats?.premium?.row?.length ?? 1) - 1
                    : 0}
                </h4>
                <h4 className={selectClass == 'executiva' ? '' : 'hide'}>
                  {content?.airplaneSeats?.executive?.row?.length > 0
                    ? (content?.airplaneSeats?.executive?.row?.length ?? 1) - 1
                    : 0}
                </h4>
                <h4 className={selectClass == 'economica' ? '' : 'hide'}>
                  {content?.airplaneSeats?.economic?.row?.length > 0
                    ? (content?.airplaneSeats?.economic?.row?.length ?? 1) - 1
                    : 0}
                </h4>
                <button
                  onClick={() => insertFileira()}
                  className="btnMorLess"
                  title="Inserir"
                >
                  <BsFillPlusCircleFill />
                </button>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-12">
                <label>Corredores</label>
              </div>
              <div className="col">
                <select
                  className={selectClass == 'primeiraClasse' ? '' : 'hide'}
                  value={selectCorredor}
                  onChange={(e) => setSelectCorredor(e.target.value)}
                >
                  <option value=""> </option>
                  {content?.airplaneSeats?.firstClass?.column?.map(
                    (colun, index) => (
                      <option
                        value={colun.column}
                        key={index}
                        disabled={checkSelectCorredor(
                          colun.column,
                          'primeiraClasse',
                        )}
                      >
                        Após a coluna - {formatLabelColumn(colun.column, '1')}
                      </option>
                    ),
                  )}
                </select>

                <select
                  className={selectClass == 'premium' ? '' : 'hide'}
                  value={selectCorredor}
                  onChange={(e) => setSelectCorredor(e.target.value)}
                >
                  {content?.airplaneSeats?.premium?.column?.map(
                    (colun, index) => (
                      <option
                        value={colun.column}
                        key={index}
                        disabled={checkSelectCorredor(colun.column, 'premium')}
                      >
                        Após a coluna - {formatLabelColumn(colun.column, '2')}
                      </option>
                    ),
                  )}
                </select>

                <select
                  className={selectClass == 'executiva' ? '' : 'hide'}
                  value={selectCorredor}
                  onChange={(e) => setSelectCorredor(e.target.value)}
                >
                  {content?.airplaneSeats?.executive?.column?.map(
                    (colun, index) => (
                      <option
                        value={colun.column}
                        key={index}
                        disabled={checkSelectCorredor(
                          colun.column,
                          'executiva',
                        )}
                      >
                        Após a coluna - {formatLabelColumn(colun.column, '3')}
                      </option>
                    ),
                  )}
                </select>

                <select
                  className={selectClass == 'economica' ? '' : 'hide'}
                  value={selectCorredor}
                  onChange={(e) => setSelectCorredor(e.target.value)}
                >
                  {content?.airplaneSeats?.economic?.column?.map(
                    (colun, index) => (
                      <option
                        value={colun.column}
                        key={index}
                        disabled={checkSelectCorredor(
                          colun.column,
                          'economica',
                        )}
                      >
                        Após a coluna - {formatLabelColumn(colun.column, '4')}
                      </option>
                    ),
                  )}
                </select>
              </div>
              <div className="col-3">
                <button
                  onClick={() => insertCorredor()}
                  className={
                    selectCorredor
                      ? 'btnMorLess'
                      : 'btnMorLess text-secondary opacity-50'
                  }
                  title="Inserir"
                  disabled={selectCorredor ? false : true}
                >
                  <BsFillPlusCircleFill />
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <table className="listTable table-hover table">
                  <tbody
                    className={selectClass == 'primeiraClasse' ? '' : 'hide'}
                  >
                    {content?.aisle?.firstClass?.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>
                            Após a coluna -{' '}
                            {formatLabelColumn(data.column, '1')}
                          </span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delCorredor(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={selectClass == 'premium' ? '' : 'hide'}>
                    {content?.aisle?.premium?.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>
                            Após a coluna -{' '}
                            {formatLabelColumn(data.column, '2')}
                          </span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delCorredor(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={selectClass == 'executiva' ? '' : 'hide'}>
                    {content?.aisle?.executive?.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>
                            Após a coluna -{' '}
                            {formatLabelColumn(data.column, '3')}
                          </span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delCorredor(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={selectClass == 'economica' ? '' : 'hide'}>
                    {content?.aisle?.economic?.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>
                            Após a coluna -{' '}
                            {formatLabelColumn(data.column, '4')}
                          </span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delCorredor(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-3">
              <div className="col-12">
                <label>Saídas de Emergência</label>
              </div>
              <div className="col">
                <select
                  className={selectClass == 'primeiraClasse' ? '' : 'hide'}
                  value={selectFileira}
                  onChange={(e) => setSelectFileira(e.target.value)}
                >
                  <option value=""> </option>
                  {content?.airplaneSeats?.firstClass?.row?.map(
                    (data, index) => (
                      <option
                        value={index == 0 ? '' : data.row}
                        key={index}
                        className={
                          index == '0' || data.row == '0' ? 'hide' : ''
                        }
                      >
                        Após a fileira - {data.row}
                      </option>
                    ),
                  )}
                </select>
                <select
                  className={selectClass == 'premium' ? '' : 'hide'}
                  value={selectFileira}
                  onChange={(e) => setSelectFileira(e.target.value)}
                >
                  {content?.airplaneSeats?.premium?.row?.map((data, index) => (
                    <option
                      value={
                        index == 0
                          ? ''
                          : soma(
                              data.row,
                              content?.airplaneSeats?.firstClass?.row?.length -
                                1,
                              0,
                              0,
                            )
                      }
                      key={index}
                    >
                      {data.row == '0'
                        ? ' '
                        : 'Após a fileira - ' +
                          soma(
                            data.row,
                            content?.airplaneSeats?.firstClass?.row?.length - 1,
                            0,
                            0,
                          )}
                    </option>
                  ))}
                </select>

                <select
                  className={selectClass == 'executiva' ? '' : 'hide'}
                  value={selectFileira}
                  onChange={(e) => setSelectFileira(e.target.value)}
                >
                  {content?.airplaneSeats?.executive?.row?.map(
                    (data, index) => (
                      <option
                        value={
                          index == 0
                            ? ''
                            : soma(
                                data.row,
                                content?.airplaneSeats?.firstClass?.row
                                  ?.length - 1,
                                content?.airplaneSeats?.premium?.row?.length -
                                  1,
                                0,
                              )
                        }
                        key={index}
                      >
                        {data.row == '0'
                          ? ' '
                          : 'Após a fileira - ' +
                            soma(
                              data.row,
                              content?.airplaneSeats?.firstClass?.row?.length -
                                1,
                              content?.airplaneSeats?.premium?.row?.length - 1,
                              0,
                            )}
                      </option>
                    ),
                  )}
                </select>

                <select
                  className={selectClass == 'economica' ? '' : 'hide'}
                  value={selectFileira}
                  onChange={(e) => setSelectFileira(e.target.value)}
                >
                  {content?.airplaneSeats?.economic?.row?.map((data, index) => (
                    <option
                      value={
                        index == 0
                          ? ''
                          : soma(
                              data.row,
                              content?.airplaneSeats?.firstClass?.row?.length -
                                1,
                              content?.airplaneSeats?.premium?.row?.length - 1,
                              content?.airplaneSeats?.executive?.row?.length -
                                1,
                            )
                      }
                      key={index}
                    >
                      {data.row == '0'
                        ? ' '
                        : 'Após a fileira - ' +
                          soma(
                            data.row,
                            content?.airplaneSeats?.firstClass?.row?.length - 1,
                            content?.airplaneSeats?.premium?.row?.length - 1,
                            content?.airplaneSeats?.executive?.row?.length - 1,
                          )}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-3">
                <button
                  onClick={() => insertSaidaEmerg()}
                  className={
                    selectFileira
                      ? 'btnMorLess'
                      : 'btnMorLess text-secondary opacity-50'
                  }
                  title="Inserir"
                  disabled={selectFileira ? false : true}
                >
                  <BsFillPlusCircleFill />
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <table className="listTable table-hover table">
                  <tbody
                    className={selectClass == 'primeiraClasse' ? '' : 'hide'}
                  >
                    {content?.emergencyExit?.firstClass?.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>Após a fileira - {data?.row}</span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delSaidaEmerg(data?.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={selectClass == 'premium' ? '' : 'hide'}>
                    {content?.emergencyExit?.premium?.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>Após a fileira - {data.row}</span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delSaidaEmerg(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={selectClass == 'executiva' ? '' : 'hide'}>
                    {content?.emergencyExit?.executive?.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>Após a fileira - {data.row}</span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delSaidaEmerg(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tbody className={selectClass == 'economica' ? '' : 'hide'}>
                    {content?.emergencyExit?.economic?.map((data, index) => (
                      <tr key={index}>
                        <td className="delCorredor">
                          <span>Após a fileira - {data.row}</span>
                        </td>
                        <td
                          className="delCorredor text-center"
                          onClick={() => delSaidaEmerg(data.id)}
                        >
                          <BiTrash />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-6">
                <label>Serviço de Bordo</label>
                {servicoBordoData.map((data) => (
                  <div className="toggleList" key={data.id}>
                    <div className={data.serviceName ? '' : 'hide'}>
                      <BsToggleOff
                        className={
                          checkServicoBordo(data.id) ? 'hide' : 'BsToggleOff'
                        }
                        onClick={() => toggleServicoBordo(data.id)}
                      />
                      <BsToggleOn
                        className={checkServicoBordo(data.id) ? '' : 'hide'}
                        onClick={() => toggleServicoBordo(data.id)}
                      />
                    </div>
                    <span>{data.serviceName}</span>
                  </div>
                ))}
              </div>
              <div className="col-6">
                <label>Características Assentos</label>
                {assentoClasseData.map((data) => (
                  <div className="toggleList" key={data.id}>
                    <div className={data.feature ? '' : 'hide'}>
                      <BsToggleOff
                        className={
                          checkAssentosClasse(data.id) ? 'hide' : 'BsToggleOff'
                        }
                        onClick={() => toggleAssentosClasse(data.id)}
                      />
                      <BsToggleOn
                        className={checkAssentosClasse(data.id) ? '' : 'hide'}
                        onClick={() => toggleAssentosClasse(data.id)}
                      />
                    </div>
                    <span>{data.feature}</span>
                  </div>
                ))}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <button type="button" onClick={() => gerarRota()}>
                  <span>Criar rotas</span>
                </button>
              </div>
            </div>
          </div>

          <div className={!selectClass ? 'col-5 interCol p-5' : 'hide'}>
            <div className="col-12 titleAeronave">
              <img
                className={content?.airline?.logo ? 'imgCia' : 'hide'}
                src={content?.airline?.logo}
              />{' '}
              {content?.airline?.logo ? '' : content?.airline?.name}{' '}
              <h4>{content?.manufacture?.aircraftName}</h4>
            </div>

            <div className="mb-0 mt-5">
              <h5>
                <b>Por favor, selecione a Categoria.</b>
              </h5>
            </div>

            <div className="col-12 mt-3 ">
              <ul className="nav nav-pills nav-justified tabAeronave">
                <li
                  className="nav-item"
                  onClick={() => changeSelectClass('primeiraClasse')}
                >
                  <span
                    className="nav-link btnIni"
                    aria-current={selectClass == 'primeiraClasse' ? 'page' : ''}
                  >
                    {content?.airplaneSeats?.firstClass?.label}
                  </span>
                </li>
                <li
                  className="nav-item"
                  onClick={() => changeSelectClass('premium')}
                >
                  <span
                    className="nav-link btnIni"
                    aria-current={selectClass == 'premium' ? 'page' : ''}
                  >
                    {content?.airplaneSeats?.premium?.label}
                  </span>
                </li>
                <li
                  className="nav-item"
                  onClick={() => changeSelectClass('executiva')}
                >
                  <span
                    className="nav-link btnIni"
                    aria-current={selectClass == 'executiva' ? 'page' : ''}
                  >
                    {content?.airplaneSeats?.executive?.label}
                  </span>
                </li>
                <li
                  className="nav-item"
                  onClick={() => changeSelectClass('economica')}
                >
                  <span
                    className="nav-link btnIni"
                    aria-current={selectClass == 'economica' ? 'page' : ''}
                  >
                    {content?.airplaneSeats?.economic?.label}
                  </span>
                </li>
              </ul>
            </div>

            <div className="row">
              <div className="col-12">
                <button type="button" onClick={() => gerarRota()}>
                  <span>Criar rotas</span>
                </button>
              </div>
            </div>
          </div>

          <div className="col-7">
            <table className="mapaAssentos">
              <thead>
                <tr>
                  <td></td>
                  <td colSpan={totalizadorColunas()}>
                    <div className="frenteAeronave"></div>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="tdBordaDir"></td>
                  <td colSpan={totalizadorColunas()} className="tdBordaDir">
                    <div className="saidaEmergencia">
                      <div className="exit">EXIT</div>
                      <div className="exit">EXIT</div>
                    </div>
                  </td>
                </tr>
                {/*Primeira Classe ----------------------------- */}
                <tr
                  className={
                    content?.airplaneSeats?.firstClass?.row?.length > 1
                      ? ''
                      : 'hide2'
                  }
                >
                  <td className="tdBordaDir"></td>
                  <td colSpan={totalizadorColunas()} className="tdBordaDir">
                    <div
                      className={
                        selectClass == 'primeiraClasse'
                          ? 'divideClass fileiraAssentosSelect'
                          : 'divideClass'
                      }
                    >
                      {content?.airplaneSeats?.firstClass?.row?.length > 1
                        ? content?.airplaneSeats?.firstClass?.label
                        : ''}
                    </div>
                  </td>
                </tr>

                {content?.airplaneSeats?.firstClass?.row?.map((v) => (
                  <tr key={v.row} className={v.row == '0' ? '' : 'fileiraUser'}>
                    <td
                      scope="col"
                      className={v.type == 'asa' ? 'asaEsq' : 'tdBordaDir'}
                    >
                      <div className={v.row == '0' ? 'hide' : 'fileiraNumber'}>
                        <button
                          className="btnAssentosEdit"
                          onClick={() => changeFileira(v.row, v.type, v.id)}
                        >
                          {v.row}
                        </button>
                      </div>
                    </td>
                    <td className={v.type == 'asa' ? 'asaDir' : 'tdBordaDir'}>
                      <div
                        className={
                          v.type == 'emergencia'
                            ? 'hide'
                            : selectClass == 'primeiraClasse'
                              ? 'fileiraAssentos fileiraAssentosSelect'
                              : 'fileiraAssentos'
                        }
                      >
                        {content?.airplaneSeats?.firstClass?.column?.map(
                          (data) => (
                            <div key={data.row} className="flex">
                              <div className="assentos">
                                <div
                                  className={
                                    v.row == 0 ? 'hide' : 'primeiraClasse'
                                  }
                                >
                                  <div data-title="Assento de Emergência">
                                    <MdOutlineStar
                                      className={
                                        checkAssentoEspecial(
                                          'primeiraClasse',
                                          data.column,
                                        )
                                          ? 'starEspecial'
                                          : 'hide'
                                      }
                                    />
                                  </div>
                                </div>
                                <button
                                  className={
                                    v.row == 0 ? 'btnAssentos' : 'hide'
                                  }
                                  onClick={() =>
                                    changeLabelColumn(data.column, '1')
                                  }
                                >
                                  <span data-title="Alterar Label">
                                    {formatLabelColumn(data.column, '1')}
                                  </span>
                                </button>
                              </div>
                              <div
                                className={
                                  checkCorredor(data.column, 'primeiraClasse')
                                    ? 'assentos'
                                    : 'hide'
                                }
                              >
                                <div className="primeiraClasseCorredor"></div>
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                      <div
                        className={
                          checkSaidaEmerg(v.row, 'primeiraClasse')
                            ? v.type == 'asa'
                              ? selectClass == 'primeiraClasse'
                                ? 'saidaEmergenciaAsa fileiraAssentosSelect'
                                : 'saidaEmergenciaAsa'
                              : selectClass == 'primeiraClasse'
                                ? 'saidaEmergencia fileiraAssentosSelect'
                                : 'saidaEmergencia'
                            : 'hide'
                        }
                      >
                        <div className="exit">EXIT</div>
                        <div className="exit">EXIT</div>
                      </div>
                    </td>
                  </tr>
                ))}

                {/*Premium ----------------------------- */}
                <tr
                  className={
                    content?.airplaneSeats?.premium?.row?.length > 1
                      ? ''
                      : 'hide'
                  }
                >
                  <td className="tdBordaDir"></td>
                  <td colSpan={totalizadorColunas()} className="tdBordaDir">
                    <div
                      className={
                        selectClass == 'premium'
                          ? 'divideClass fileiraAssentosSelect'
                          : 'divideClass'
                      }
                    >
                      {content?.airplaneSeats?.premium?.row?.length > 1
                        ? content?.airplaneSeats?.premium?.label
                        : 'Zero'}
                    </div>
                  </td>
                </tr>
                {content?.airplaneSeats?.premium?.row?.map((v) => (
                  <tr key={v.row} className={v.row == '0' ? '' : 'fileiraUser'}>
                    <td
                      scope="col"
                      className={v.type == 'asa' ? 'asaEsq' : 'tdBordaDir'}
                    >
                      <div className={v.row == '0' ? 'hide' : 'fileiraNumber'}>
                        <button
                          className="btnAssentosEdit"
                          onClick={() =>
                            changeFileira(
                              contFileiraPremium(v.row),
                              v.type,
                              v.id,
                            )
                          }
                        >
                          {contFileiraPremium(v.row)}
                        </button>
                      </div>
                    </td>
                    <td className={v.type == 'asa' ? 'asaDir' : 'tdBordaDir'}>
                      <div
                        className={
                          v.row == 'emergencia'
                            ? 'hide'
                            : selectClass == 'premium'
                              ? 'fileiraAssentos fileiraAssentosSelect'
                              : 'fileiraAssentos'
                        }
                      >
                        {content?.airplaneSeats?.premium?.column?.map(
                          (data) => (
                            <div key={v.row} className="flex">
                              <div className="assentos">
                                <div
                                  className={v.row == '0' ? 'hide' : 'premium'}
                                >
                                  <span
                                    className={
                                      data.type == 'corredor' ? 'hide' : ''
                                    }
                                  >
                                    <div data-title="Assento de Emergência">
                                      <MdOutlineStar
                                        className={
                                          checkAssentoEspecial(
                                            'premium',
                                            contFileiraPremium(v.row),
                                          )
                                            ? 'starEspecial'
                                            : 'hide'
                                        }
                                      />
                                    </div>
                                  </span>
                                </div>
                                <button
                                  className={
                                    v.row == '0' ? 'btnAssentos' : 'hide'
                                  }
                                  onClick={() =>
                                    changeLabelColumn(data.column, '2')
                                  }
                                >
                                  <span data-title="Alterar Label">
                                    {formatLabelColumn(data.column, '2')}
                                  </span>
                                </button>
                              </div>
                              <div
                                className={
                                  checkCorredor(data.column, 'premium')
                                    ? 'assentos'
                                    : 'hide'
                                }
                              >
                                <div className="premiumCorredor"></div>
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                      <div
                        className={
                          checkSaidaEmerg(contFileiraPremium(v.row), 'premium')
                            ? v.tipo == 'asa'
                              ? selectClass == 'premium'
                                ? 'saidaEmergenciaAsa fileiraAssentosSelect'
                                : 'saidaEmergenciaAsa'
                              : selectClass == 'premium'
                                ? 'saidaEmergencia fileiraAssentosSelect'
                                : 'saidaEmergencia'
                            : 'hide'
                        }
                      >
                        <div className="exit">EXIT</div>
                        <div className="exit">EXIT</div>
                      </div>
                    </td>
                  </tr>
                ))}

                {/*Executiva ----------------------------- */}
                <tr
                  className={
                    content?.airplaneSeats?.premium?.row?.length > 1
                      ? ''
                      : 'hide'
                  }
                >
                  <td className="tdBordaDir"></td>
                  <td colSpan={totalizadorColunas()} className="tdBordaDir">
                    <div
                      className={
                        selectClass == 'executiva'
                          ? 'divideClass fileiraAssentosSelect'
                          : 'divideClass'
                      }
                    >
                      {content?.airplaneSeats?.executive?.row?.length > 1
                        ? content?.airplaneSeats?.executive?.label
                        : ''}
                    </div>
                  </td>
                </tr>
                {content?.airplaneSeats?.executive?.row?.map((v) => (
                  <tr key={v.row} className={v.row == '0' ? '' : 'fileiraUser'}>
                    <td
                      scope="col"
                      className={v.type == 'asa' ? 'asaEsq' : 'tdBordaDir'}
                    >
                      <div className={v.row == '0' ? 'hide' : 'fileiraNumber'}>
                        <button
                          className="btnAssentosEdit"
                          onClick={() =>
                            changeFileira(
                              contFileiraExecutiva(v.row),
                              v.type,
                              v.id,
                            )
                          }
                        >
                          {contFileiraExecutiva(v.row)}
                        </button>
                      </div>
                    </td>
                    <td className={v.type == 'asa' ? 'asaDir' : 'tdBordaDir'}>
                      <div
                        className={
                          v.type == 'emergencia'
                            ? 'hide'
                            : selectClass == 'executiva'
                              ? 'fileiraAssentos fileiraAssentosSelect'
                              : 'fileiraAssentos'
                        }
                      >
                        {content?.airplaneSeats?.executive?.column?.map(
                          (data) => (
                            <div key={data.id} className="flex">
                              <div className="assentos">
                                <div
                                  className={
                                    v.row == '0' ? 'hide' : 'executiva'
                                  }
                                >
                                  <span
                                    className={
                                      data.type == 'corredor' ? 'hide' : ''
                                    }
                                  >
                                    <div data-title="Assento de Emergência">
                                      <MdOutlineStar
                                        className={
                                          checkAssentoEspecial(
                                            'executiva',
                                            contFileiraExecutiva(v.row),
                                          )
                                            ? 'starEspecial'
                                            : 'hide'
                                        }
                                      />
                                    </div>
                                  </span>
                                </div>
                                <button
                                  className={
                                    v.row == '0' ? 'btnAssentos' : 'hide'
                                  }
                                  onClick={() =>
                                    changeLabelColumn(data.column, '3')
                                  }
                                >
                                  <span data-title="Alterar Label">
                                    {formatLabelColumn(data.column, '3')}
                                  </span>
                                </button>
                              </div>
                              <div
                                className={
                                  checkCorredor(data.column, 'executiva')
                                    ? 'assentos'
                                    : 'hide'
                                }
                              >
                                <div className="executivaCorredor"></div>
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                      <div
                        className={
                          checkSaidaEmerg(
                            contFileiraExecutiva(v.row),
                            'executiva',
                          )
                            ? v.type == 'asa'
                              ? selectClass == 'executiva'
                                ? 'saidaEmergenciaAsa fileiraAssentosSelect'
                                : 'saidaEmergenciaAsa'
                              : selectClass == 'premium'
                                ? 'saidaEmergencia fileiraAssentosSelect'
                                : 'saidaEmergencia'
                            : 'hide'
                        }
                      >
                        <div className="exit">EXIT</div>
                        <div className="exit">EXIT</div>
                      </div>
                    </td>
                  </tr>
                ))}

                {/*Econômica ----------------------------- */}
                <tr
                  className={
                    content?.airplaneSeats?.economic?.row?.length > 1
                      ? ''
                      : 'hide'
                  }
                >
                  <td className="tdBordaDir"></td>
                  <td colSpan={totalizadorColunas()} className="tdBordaDir">
                    <div
                      className={
                        selectClass == 'economica'
                          ? 'divideClass fileiraAssentosSelect'
                          : 'divideClass'
                      }
                    >
                      {content?.airplaneSeats?.economic?.row?.length > 1
                        ? content?.airplaneSeats?.economic?.label
                        : ''}
                    </div>
                  </td>
                </tr>
                {content?.airplaneSeats?.economic?.row?.map((v) => (
                  <tr key={v.row} className={v.row == '0' ? '' : 'fileiraUser'}>
                    <td
                      scope="col"
                      className={v.type == 'asa' ? 'asaEsq' : 'tdBordaDir'}
                    >
                      <div className={v.row == '0' ? 'hide' : 'fileiraNumber'}>
                        <button
                          className="btnAssentosEdit"
                          onClick={() =>
                            changeFileira(
                              contFileiraEconomica(v.row),
                              v.type,
                              v.id,
                            )
                          }
                        >
                          {contFileiraEconomica(v.row)}
                        </button>
                      </div>
                    </td>
                    <td className={v.type == 'asa' ? 'asaDir' : 'tdBordaDir'}>
                      <div
                        className={
                          v.type == 'emergencia'
                            ? 'hide'
                            : selectClass == 'economica'
                              ? 'fileiraAssentos fileiraAssentosSelect'
                              : 'fileiraAssentos'
                        }
                      >
                        {content?.airplaneSeats?.economic?.column?.map(
                          (data) => (
                            <div key={data.id} className="flex">
                              <div className="assentos">
                                <div
                                  className={
                                    v.row == '0' ? 'hide' : 'economica'
                                  }
                                >
                                  <span
                                    className={
                                      data.type == 'corredor' ? 'hide' : ''
                                    }
                                  >
                                    <div data-title="Assento de Emergência">
                                      <MdOutlineStar
                                        className={
                                          checkAssentoEspecial(
                                            'economica',
                                            contFileiraEconomica(v.row),
                                          )
                                            ? 'starEspecial'
                                            : 'hide'
                                        }
                                      />
                                    </div>
                                  </span>
                                </div>
                                <button
                                  className={
                                    v.row == '0' ? 'btnAssentos' : 'hide'
                                  }
                                  onClick={() =>
                                    changeLabelColumn(data.colmn, '4')
                                  }
                                >
                                  <span data-title="Alterar Label">
                                    {formatLabelColumn(data.column, '4')}
                                  </span>
                                </button>
                              </div>
                              <div
                                className={
                                  checkCorredor(data.column, 'economica')
                                    ? 'assentos'
                                    : 'hide'
                                }
                              >
                                <div className="economicaCorredor"></div>
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                      <div
                        className={
                          checkSaidaEmerg(
                            contFileiraEconomica(v.row),
                            'economica',
                          )
                            ? v.type == 'asa'
                              ? selectClass == 'economica'
                                ? 'saidaEmergenciaAsa fileiraAssentosSelect'
                                : 'saidaEmergenciaAsa'
                              : selectClass == 'premium'
                                ? 'saidaEmergencia fileiraAssentosSelect'
                                : 'saidaEmergencia'
                            : 'hide'
                        }
                      >
                        <div className="exit">EXIT</div>
                        <div className="exit">EXIT</div>
                      </div>
                    </td>
                  </tr>
                ))}

                {minFileiras.map((line, index) => (
                  <tr
                    key={index}
                    className={
                      index > minFileiras.length - totalizadorFileiras()
                        ? 'hide'
                        : ''
                    }
                  >
                    <td className="tdBordaDir"></td>
                    <td colSpan={totalizadorColunas()} className="tdBordaDir">
                      <div className="divideClass"></div>
                    </td>
                  </tr>
                ))}

                <tr>
                  <td className="tdBordaDir"></td>
                  <td colSpan={totalizadorColunas()} className="tdBordaDir">
                    <div className="saidaEmergencia fundoEmergencia">
                      <div className="exit">EXIT</div>
                      <div className="exit">EXIT</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td colSpan={totalizadorColunas()}>
                    <div className="frundoAeronave"></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
