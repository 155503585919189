import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';

export function formatDate(date) {
  if (date) {
    const formattedDate = dayjs(date).locale('pt-br').format('DD/MM/YYYY');

    return formattedDate[0].toUpperCase() + formattedDate.substring(1);
  }

  return '-';
}

export function formatDateAereoReservas(date) {
  if (date) {
    const formattedDate = dayjs(date)
      .locale('pt-br')
      .format('ddd, DD MMM YYYY');

    return formattedDate[0].toUpperCase() + formattedDate.substring(1);
  }
  return '-';
}

export function formatDayMonth(date) {
  if (date) {
    const formattedDate = dayjs(date).locale('pt-br').format('DD MMM');
    return formattedDate;
  }
  return '-';
}
